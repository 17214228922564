import ticketSessionTemplate from './ticketSession.html';
import ticketSessionTouchTemplate from './touch/ticketSession.html';

(function () {
  angular.module('7Shop.Core').component('sTicketsSessionStatus', {
    templateUrl: ['Touch', function (Touch) {
      return Touch.isTouch()
        ? ticketSessionTouchTemplate : ticketSessionTemplate;
    }],
    controller: sTicketsSessionStatusCtrl
  });

  function sTicketsSessionStatusCtrl(
    popupService,
    TicketsSessions
  ) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.sessions = TicketsSessions.sessions;
    };

    ctrl.openCalculator = function () {
      popupService.showPopup('calculator');
    };
  }
})();
