/* @ngInject */
export default function zodiacInterceptor(
  NabENV,
  LoginData,
  LocalData,
  companyService,
  ShopEnvironment
) {
  function isValidUrl(url) {
    return url.includes(NabENV.api.zodiac.url);
  }

  return {
    request: function (request) {
      if (isValidUrl(request.url)) {
        const dc = ShopEnvironment.getDc();
        request.headers['X-Nsft-SCD-Device-UUID'] = LoginData.device.uuid;
        request.headers['X-Nsft-SCD-Company-UUID'] = companyService.getUuid();
        request.headers['X-Nsft-SCD-Company-ID'] = LocalData.betshop.companyId;
        request.headers['X-Nsft-SCD-Betshop-ID'] = LocalData.betshop.betshopId;
        request.headers.Authorization = 'Bearer ' + sessionStorage.shopToken;

        if (dc) {
          request.headers['X-Nsft-SCD-DC'] = dc;
        }
      }

      return request;
    }
  };
}
