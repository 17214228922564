import router from '@uirouter/angularjs';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import UserModule from '../src/user';
// eslint-disable-next-line import/no-cycle
import ShopModules from '../src/modules';
import CoreModule from '../src/core';
import CommonModule from '../src/common';
import integratorModule from '../src/modules/integrator';
import CustomerCardsModule from '../src/modules/customer_cards';
import SevenMaintenanceModule from '../src/modules/seven_maintenance';

/**
   * @module "7Shop.Bootstrap"
   */
angular.module('7Shop.Bootstrap', [
  router,
  'SevenClient.Config',
  'SevenClientCore',
  CommonModule,
  UserModule,
  ShopModules,
  CoreModule,
  integratorModule,
  CustomerCardsModule,
  SevenMaintenanceModule
]);
