import { keys, difference } from 'underscore';

(function () {
  angular.module('7Shop.Core')
    .service('Shortcuts', Shortcuts);

  /**
   *
   * @class Shortcuts
   * @memberOf module:"7Shop.Core"
   */
  function Shortcuts(
    $rootScope,
    $log,
    NabENV,
    GravitySettings
  ) {
    var self = this;
    var deregister;

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @type {Array.<S_Shortcut>}
     */
    this.shortcuts = NabENV.shortcuts || [];

    /**
     * Check does shortcut has required properties
     * @param {S_Shortcut} shortcut
     */
    function hasRequiredProperties(shortcut) {
      var listOfKeys = keys(shortcut);
      var result = difference(['category', 'name', 'shortcut'], listOfKeys);
      if (result.length) {
        $log.error('[7Shop.Shortcuts] Shortcut cannot be registered because it is missing some properties', {
          code: 'S_SHORTCUT_CANNOT_REGISTER',
          details: {
            shortcut,
            result
          }
        });
        return false;
      }

      return true;
    }

    function validateShortcuts() {
      var systemReservedShortcuts = self.shortcuts.filter(function (shortcut) {
        return shortcut.shortcutReserved === true;
      });
      var changeableShortcuts = self.shortcuts.filter(function (shortcut) {
        return angular.isUndefined(shortcut.shortcutReserved)
          || shortcut.shortcutReserved === false;
      });

      self.shortcuts.forEach(function (shortcut, index) {
        var isDuplicate = self.shortcuts.find(function (shortcutDuplicate, duplicateIndex) {
          return shortcutDuplicate.shortcut.toLowerCase() === shortcut.shortcut.toLowerCase()
            && index !== duplicateIndex && shortcut.category === shortcutDuplicate.category;
        });

        if (isDuplicate && shortcut.shortcut !== '') {
          $log.warn('[7Shop.Shortcuts] Shortcut is already taken key by action', {
            shortcut
          });
        }
      });

      changeableShortcuts.forEach(function (shortcut) {
        var isReserved = systemReservedShortcuts.find(function (reservedShortcut) {
          return reservedShortcut.shortcut.toLowerCase() === shortcut.shortcut.toLowerCase();
        });

        if (isReserved) {
          $log.warn('[7Shop.Shortcuts]  Shortcut action already taken', {
            action: shortcut.action.toUpperCase(),
            reservedAction: isReserved.action.toUpperCase()
          });
        }
      });
    }

    deregister = $rootScope.$on('SevenClientCore:ready', function () {
      validateShortcuts();
      deregister();

      const cmsShortcuts = GravitySettings.getByKey('shortcuts');
      if (!cmsShortcuts?.length) {
        return;
      }

      cmsShortcuts.forEach((companyShortcut) => {
        // here is place where we override locally defined shortcuts
        // with company specific from CMS
        const foundShortcutIndex = self.shortcuts
          .findIndex(({ action }) => action === companyShortcut.action);
        if (foundShortcutIndex >= 0) {
          // override shortcuts with company specific
          angular.extend(self.shortcuts[foundShortcutIndex], companyShortcut);
        } else {
          self.registerShortcuts([companyShortcut]);
        }
      });
    });

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param category
     */
    this.getShortcutByCategory = function (category) {
      return self.shortcuts.filter(function (shortcut) {
        return shortcut.category === category;
      });
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param shortcutAction
     */
    this.disableShortcut = function (shortcutAction) {
      var foundShortcut = self.shortcuts.find(function (shortcut) {
        return shortcut.action === shortcutAction;
      });

      if (foundShortcut) {
        foundShortcut.active = false;
      }
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param shortcutKey
     */
    this.isShortcutDisabled = function (shortcutKey) {
      return self.shortcuts.find(function (shortcut) {
        return shortcut.shortcut.toLowerCase() === shortcutKey.toLowerCase()
          && !shortcut.active;
      });
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param updatedShortcut
     */
    this.updateShortcut = function (updatedShortcut) {
      // find shortcut to update by action
      var foundShortcut = self.shortcuts.find(function (shortcut) {
        return shortcut.action === updatedShortcut.action;
      });

      if (foundShortcut) {
        // prevent updating reserved shortcuts
        if (foundShortcut.shortcutReserved) {
          $log.warn('[7Shop.Shortcuts] You cannot change reserved shortcut key.', {
            shortcut: foundShortcut.shortcutReserved
          });
          return false;
        }
        angular.extend(foundShortcut, updatedShortcut);
      }

      return foundShortcut;
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param {Array} shortcuts - list of shortcuts to return by unique app action
     * @return {Array.<S_Shortcut>}
     */
    this.getShortcuts = function (shortcuts) {
      return self.shortcuts.filter(function (shortcut) {
        return shortcuts.indexOf(shortcut.action) >= 0;
      });
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param {String} shortcutCode
     */
    this.getByShortcut = function (shortcutCode) {
      return self.shortcuts.filter(function (shortcut) {
        return shortcut.shortcut.toLowerCase() === shortcutCode.toLowerCase();
      });
    };

    this.getByShortcutAndCategory = function (shortcutCode, category) {
      return self.shortcuts.filter(function (shortcut) {
        return shortcut.shortcut.toLowerCase() === shortcutCode.toLowerCase()
          && shortcut.category === category;
      });
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param action
     * @param key
     */
    this.isShortcut = function (key, action) {
      var shortcut = self.getShortcuts([action]);

      if (shortcut && shortcut.length) {
        if (shortcut[0].shortcut.toLowerCase() === key.toLowerCase()) {
          return shortcut[0];
        }
      }

      return false;
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param {Number} keyCode
     */
    this.findByKeyCode = function (keyCode) {
      return self.shortcuts.find(function (shortcut) {
        if (shortcut.keyCodes) {
          return shortcut.keyCodes.indexOf(keyCode) >= 0;
        }

        return false;
      });
    };

    /**
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param {Array.<{action: string, keyCodes: Array, active: boolean, category: string, shortcut: string, name: string}>} shortcuts
     */
    this.registerShortcuts = function (shortcuts) {
      const failedToRegister = [];
      shortcuts.forEach(function (shortcut) {
        if (self.isDuplicate(shortcut) || !hasRequiredProperties(shortcut)) {
          failedToRegister.push(shortcut);
          return;
        }

        self.shortcuts.push(shortcut);
      });

      return failedToRegister;
    };

    /**
     *
     * @memberOf module:"7Shop.Core".Shortcuts
     * @param shortcut
     * @return {Array.<number>|Number|*}
     */
    this.isDuplicate = function (shortcut) {
      var isKeyCodeDuplicate = shortcut.keyCodes && shortcut.keyCodes.filter(function (key) {
        return self.findByKeyCode(key);
      }).length;
      var isShortcutDuplicate = shortcut.shortcut && self.getByShortcutAndCategory(shortcut.shortcut, shortcut.category).length;
      if (isKeyCodeDuplicate || isShortcutDuplicate) {
        $log.debug('[7Shop.Shortcuts] Shortcut is already registered', {
          code: 'S_SHORTCUT_ALREADY_REGISTERED',
          shortcut
        });
        return true;
      }

      return false;
    };
  }
})();

/**
 *
 * @memberOf module:"7Shop.Core".Shortcuts
 * @typedef {Object} S_Shortcut
 *
 * @property {string} action - Unique name of action
 * @property {string} shortcut - Friendly name representing key on keyboard
 * @property {string} param
 * @property {string} name - Translated, human readable, description of the shortcut
 * @property {boolean} active - Shortcut status, if false will be hidden from UI
 * @property {Array.<number>} keyCodes - Key codes to be used to trigger this shortcut.
 *                                       It is possible to use combination of keys like CTRL+R.
 * @property {string} tc_key - Translation key added inside https://tc.nsoft.com
 * @property {string} category - To prevent collision of keys between different categories.
 *                               E.g. LuckySix and LuckyX have both R shortcut but they are differentiated by
 *                               category.
 */
