/**
  * @ngdoc overview
  * @name 7Terminal.SevenMaintenance
  *
  * @description
  * This module handles maintenance mode in 7Shop app.
  *
*/
import angular from 'angular';
import SevenMaintenanceService from './sevenMaintenanceService';
import SevenMaintenanceApiService from './sevenMaintenanceApiService';
// eslint-disable-next-line import/no-cycle
import coreModule from '../../core';

const moduleName = angular.module('7Shop.SevenMaintenance', [coreModule, 'ui.router'])
  .service('SevenMaintenanceService', SevenMaintenanceService)
  .service('SevenMaintenanceApiService', SevenMaintenanceApiService)
  .name;

export default moduleName;
