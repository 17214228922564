import { groupBy } from 'underscore';
import templateUrlTouch from './touch/dialog.html';
import templateUrl from './dialog.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sDialog', function sDialog(Touch) {
      return {
        replace: true,
        templateUrl: function () {
          return Touch.isTouch()
            ? templateUrlTouch : templateUrl;
        },
        controller: sDialogCtrl
      };
    });

  function sDialogCtrl(
    $scope,
    $rootScope,
    NabNotifications,
    ScanSvc,
    focusNext
  ) {
    function init() {
      NabNotifications.dialog = $scope;
      $scope.nabNotifications = NabNotifications;

      /**
       * Close modal with id
       *
       * @event "7S:Dialog.Close"
       * @memberOf module:'7Shop.Common'
       * @type {Object}
       * @property {Object} data
       * @property {String} data.id - Close modal with next id
       */
      $rootScope.$on('7S:Dialog.Close', onCloseModal);

      $rootScope.$on('7S:Dialog.CloseModal', onCloseModal);
    }

    function onCloseModal(e, eventData) {
      NabNotifications.closeNotificationWithId(eventData.data.id);
    }

    // find correct action for pressed key
    function findAction(e, action) {
      if ((action.keyCode?.indexOf(e.keyCode) >= 0)
      || (action.keyboardEvents?.find((obj) => obj.key.toString().toLowerCase() === e.key.toString().toLowerCase()))) { // compare key value inside array of objects with pressed key
        return true;
      }
      return false;
    }

    $scope.onKeyAction = function (e) {
      var notification;
      var i;
      var action;

      // prevent any action while scanner is in position to
      // process something
      notification = NabNotifications.current;

      ScanSvc.scanInactiveOrFinished(e).then((finished) => {
        if (finished) {
          e.stopPropagation();
          e.stopImmediatePropagation();
          e.preventDefault();

          if (notification && notification.actions) {
            for (i = 0; i < notification.actions.length; i += 1) {
              action = notification.actions[i];
              if (findAction(e, action)) {
                NabNotifications.isActionExecuted = true;
                NabNotifications.runAction(notification, action);

                if (action) {
                  $scope.emitAction(e, notification, action);
                }
                break;
              }
            }
          }
          NabNotifications.isActionExecuted = false;
        }
      });
    };

    $scope.onClickAction = function (notification, e, action) {
      NabNotifications.isActionExecuted = true;
      NabNotifications.runAction(notification, action);

      if (action) {
        $scope.emitAction(e, notification, action);
      }

      NabNotifications.isActionExecuted = false;
    };

    $scope.onOpen = function () {
      var current = NabNotifications.current;
      // focus notification div if focus is set to true
      // and we have actions and there is no explict flag to not to show actions
      if (current && current.config.focus && (current.actions.length && current.config.showActions !== false)) {
        focusNext('1002', false, true);
      }
    };

    $scope.groupNotifications = function (type) {
      var grouped = groupBy(NabNotifications.notifications, function (notification) {
        return notification.config.type;
      });

      if (grouped[type]) {
        return grouped[type].length;
      }

      return false;
    };

    $scope.onDefaultClose = function () {
      focusNext(false, true);
    };

    $scope.emitAction = function (e, notification, action) {
      /**
       * Emits event on dialog action
       *
       * @event "7S:Widget.Event"
       * @memberOf module:'7Shop.Common'
       * @type {Object}
       * @property {String} type - It will be always set to DialogAction
       * @property {Object} action - Action that was run
       * @property {Object} action.config - Action config
       * @property {String} action.config.key - Action that was run
       * @property {String} action.config.text - Action that was run
       * @property {Array} action.config.keyCode - Action that was run
       * @property {String} [action.config.id] -Id of action
       * @property {Object} notification - Notification that was active
       */
      $rootScope.$emit('7S:Widget.Event', angular.extend({
        productId: notification.productId,
        type: 'DialogAction',
        originalEvent: e.originalEvent
      }, { action: action, notification: notification }));
    };

    init();
  }
})();
