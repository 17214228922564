/**
 *
 * @class sPrinterStatusCtrl
 * @memberOf module:"7Shop.Print"
 */
/* @ngInject */
function sPrinterStatusCtrl(ShopPrintService) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.driverState = ShopPrintService.driverState;
  };
}

export default {
  controller: sPrinterStatusCtrl,
  template: '<i class="n-i n-i-printer"'
   + ' ng-class="\'code_\' + $ctrl.driverState.status.statusLevel"></i>'
};
