/* @ngInject */
function NarApiService(
  $http,
  LocalData
) {
  const defaultIp = '127.0.0.1';

  /**
   * @name devicePower
   * @desc Reboot or poweroff device apps on give IP address
   * @param {'reboot' | 'poweroff'} action - reboot/poweroff
   * @param {string} [ip] - url to hit
   * @param {string} [token] - access token
   * @returns Promise<void>
   */
  this.devicePower = (action, ip, token) => {
    var data = {
      action: action,
      token: token || LocalData.deviceManagementToken
    };

    return $http.post(`http://${ip || defaultIp}:8081/device_power`, data);
  };
}
export default NarApiService;
