/**
   *
   * @constructor customerCardsService
   */
/* @ngInject */
function customerCardsService(
  $http,
  $log,
  $rootScope,
  $translate,
  NabENV,
  errorParser,
  companyService,
  Modules,
  NabNotifications,
  HooksManager
) {
  let customerCardsData = null;
  let currentCustomerCard = null;
  let scanListener = null;

  const setCustomerCardsData = (data) => {
    customerCardsData = data;
  };

  const getCustomerCardsData = () => (customerCardsData);

  const getCurrentCustomerCard = () => (currentCustomerCard);

  const removeCurrentCustomerCard = () => {
    currentCustomerCard = null;
    $rootScope.$emit('7S:CustomerCard.Deactivated');
  };

  const loadCustomerCardsModules = () => {
    const customerCardData = getCustomerCardsData()?.[0];

    if (customerCardData?.tasks?.length) {
      const regex = /-/g;
      customerCardData.tasks.forEach((task) => {
        Modules.registerModules({
          [task.textId.toLowerCase().replace(regex, '_')]: {
            enabled: true
          }
        });
      });

      Modules.loadModulesByPosition('afterCustomerCardModuleReady');
    }
  };

  const fetchActiveCustomerCards = () => {
    const companyUuid = companyService.getUuid();
    const query = 'channel=SHOP&status=active';
    const customerCardsUrl = `${NabENV.api.customerCards}/tenants/${companyUuid}/customerCards?${query}`;

    $http
      .get(customerCardsUrl)
      .then((customerCardsResponse) => {
        setCustomerCardsData(customerCardsResponse.data);
        loadCustomerCardsModules();
      })
      .catch((err) => {
        $log.error('[7Shop.Bootstrap] Failed to fetch customer cards', {
          ...errorParser.parseUpstream(err),
          code: 'S_CUSTOMER_CARDS_FETCH_FAILED'
        });
      });
  };

  const fetchCustomerCardDetailsById = (id) => {
    const customerCardId = getCustomerCardsData()?.[0].id;
    const companyUuid = companyService.getUuid();

    const url = `${NabENV.api.customerCards}/tenants/${companyUuid}/CustomerCards/${customerCardId}/cards/${id}`;
    return $http({
      url,
      method: 'GET'
    });
  };

  const enableScanListenerForCustomerCards = () => {
    scanListener = $rootScope.$on('7S:Scanner.ScanningFinished', function (event, eventData) {
      const validationPattern = getCustomerCardsData()?.[0].validationPattern;
      const regex = new RegExp(validationPattern);

      if (regex.test(eventData.data.code) && getCustomerCardsData()?.length > 0) {
        fetchCustomerCardDetailsById(eventData.data.code)
          .then((response) => {
            currentCustomerCard = response.data;

            $rootScope.$emit('7S:CustomerCard.Activated', {
              card: {
                firstName: response.data.client_first_name,
                lastName: response.data.client_last_name
              }
            });

            NabNotifications.show(
              {
                message: $translate.instant('customer_cards_valid_card'),
                type: 'success',
                delay: 5000
              }
            );
          })
          .catch((err) => {
            NabNotifications.show(
              {
                message: err.status === 404 ? $translate.instant('customer_cards_unknown_card') : err.data?.description || err,
                type: 'warning',
                delay: 5000
              }
            );
            removeCurrentCustomerCard();
          })
          .finally(() => {
            // eslint-disable-next-line angular/module-getter
            HooksManager.run('CustomerCards.CardDetailsFetched');
          });
      }
    });
  };

  const disableScanListenerForCustomerCards = () => {
    scanListener();
  };

  const init = () => {
    fetchActiveCustomerCards();
    enableScanListenerForCustomerCards();
  };

  return {
    fetchActiveCustomerCards,
    setCustomerCardsData,
    getCustomerCardsData,
    getCurrentCustomerCard,
    removeCurrentCustomerCard,
    fetchCustomerCardDetailsById,
    enableScanListenerForCustomerCards,
    disableScanListenerForCustomerCards,
    init
  };
}

export default customerCardsService;
