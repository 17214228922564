(function () {
  angular.module('7Shop.Core')
    .service('WorkingTime', WorkingTime);

  /**
   * @class WorkingTime
   * @memberOf module:"7Shop.Core"
   */
  function WorkingTime(
    $interval,
    $q,
    $rootScope,
    nabTrans,
    HooksManager,
    NabNotifications
  ) {
    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var timeOverTrans;
    var timeHasNotStartedTrans;
    var self = this;

    this.data = null;

    this.init = function (data) {
      this.data = data || {};
      timeOverTrans = nabTrans.translate('working.time_over', {}, true);
      timeHasNotStartedTrans = nabTrans.translate('working.time_has_not_started', {}, true);
      $interval(function () {
        var workingTimeError = self.validateAll();

        if (workingTimeError.hasError) {
          $rootScope.$emit('7S:Betslip.Block', {
            id: 'WorkingTme',
            message: workingTimeError.message,
            productId: '*'
          });
        } else {
          $rootScope.$emit('7S:Betslip.Unblock', { namespace: 'WorkingTme' });
        }
      }, 5000, 0, false);

      HooksManager.getHook('BeforeTicketPayin').tapPromise({
        name: 'BeforeTicketPayin.WorkingTimeValidation',
        fn: self._validateAction,
        stage: 30
      });
      HooksManager.getHook('WithdrawalProcessing.BeforeGetWithdrawalInfo').tapPromise({
        name: 'WithdrawalProcessing.BeforeGetWithdrawalInfo.WorkingTimeValidation',
        fn: self._validateAction
      });
      HooksManager.getHook('BeforeVoucherCreate').tapPromise({
        name: 'BeforeVoucherCreate.WorkingTimeValidation',
        fn: self._validateAction
      });
      HooksManager.getHook('DirectTransferController.BeforeSubmitDeposit').tapPromise({
        name: 'DirectTransferController.BeforeSubmitDeposit.WorkingTimeValidation',
        fn: self._validateAction
      });
      HooksManager.getHook('BeforeVoucherPayout').tapPromise({
        name: 'BeforeVoucherPayout.WorkingTimeValidation',
        fn: self._validateAction
      });
      HooksManager.getHook('BeforeVoucherCancel').tapPromise({
        name: 'BeforeVoucherCancel.WorkingTimeValidation',
        fn: self._validateAction
      });
      HooksManager.getHook('BeforeTicketCancel').tapPromise({
        name: 'BeforeTicketCancel.WorkingTimeValidation',
        fn: self._validateAction,
        stage: 50
      });
      HooksManager.getHook('BeforeTicketPayout').tapPromise({
        name: 'BeforeTicketPayout.WorkingTimeValidation',
        fn: self._validateAction,
        stage: 50
      });
    };

    this._validateAction = function (params) {
      const workingTimeError = self.validateAll();

      if (workingTimeError.hasError) {
        NabNotifications.show({
          message: workingTimeError.message,
          type: 'warning',
          delay: 3000,
          focus: false,
          id: workingTimeError.id
        });
        $rootScope.$emit('7S:Betslip.Block', {
          id: 'WorkingTme',
          message: workingTimeError.message,
          productId: '*'
        });

        return $q.reject({
          message: workingTimeError.message,
          code: 'S_WORKING_TIME_ERROR'
        });
      }
      return $q.resolve(params);
    };

    this._parseEntryIntoDate = function (entry) {
      var startDate;
      var startTimeData;
      var endDate;
      var endTimeData;

      startDate = new Date();
      startTimeData = entry.start.split(':');
      startDate.setHours(startTimeData[0]);
      startDate.setMinutes(startTimeData[1]);
      startDate.setSeconds(startTimeData[2]);
      endDate = new Date();
      endTimeData = entry.end.split(':');
      endDate.setHours(endTimeData[0]);
      endDate.setMinutes(endTimeData[1]);
      endDate.setSeconds(endTimeData[2]);

      return {
        startDate: startDate,
        endDate: endDate
      };
    };

    this.isLocationOpen = function () {
      var currentDate = new Date();
      var todayDayName = days[currentDate.getDay()];
      var workingHours = this.data.days[todayDayName];
      var i;
      var dates;
      var result = {
        id: '',
        hasError: true,
        message: ''
      };
      var flatten = [];

      for (i = 0; i < workingHours.length; i += 1) {
        dates = this._parseEntryIntoDate(workingHours[i]);
        if (currentDate.getTime() < dates.endDate.getTime()
        && currentDate.getTime() > dates.startDate.getTime()) {
          // all good, we found time slot and operator is in
          // valid working date range
          result.hasError = false;
          return result;
        }

        // add all times here, later we will use it to find is working time over
        // or not started
        flatten.push(
          {
            type: 'end',
            date: dates.endDate
          },
          {
            type: 'start',
            date: dates.startDate
          }
        );
      }

      // no working time found for current day
      // we define this as location is not open
      if (!flatten.length) {
        result.message = timeHasNotStartedTrans;
        result.id = 'workingHasNotStarted';
        return result;
      }

      // if we are here then working time is either over or not yet started,
      // let's find closes value to current date, this will decide are we gonna show
      // not started or time is finished message
      flatten.sort(function (a, b) {
        var distancea = Math.abs(currentDate.getTime() - a.date.getTime());
        var distanceb = Math.abs(currentDate.getTime() - b.date.getTime());
        return distancea - distanceb; // sort a before b, meaning a is cloer to current date
      });

      result.message = flatten[0].type === 'start' ? timeHasNotStartedTrans : timeOverTrans;
      result.id = flatten[0].type === 'start' ? 'workingHasNotStarted' : 'workingTimeOver';
      return result;
    };

    /**
     *
     * @memberOf WorkingTime
     * @return {{id: string, hasError: boolean, message: string}}
     */
    this.validateAll = function () {
      // let's just skip this validation
      // if we did not receive working time data.
      // we don't wont to interrupt playing of tickets
      if (!Object.keys(this.data).length || (this.data.days && !Object.keys(this.data.days).length)) {
        return {
          id: '',
          hasError: false,
          message: ''
        };
      }

      return this.isLocationOpen();
    };
  }
})();
