(function () {
  /**
   * @class IframeBootstrap
   * @memberof module:"7Shop.Bootstrap"
   */
  angular.module('7Shop.Bootstrap')
    .service('IframeBootstrap', IframeBootstrap);

  function IframeBootstrap(
    $window,
    $injector,
    $rootScope,
    $q,
    Modules,
    ShopEnvironment,
    GravitySettings
  ) {
    var self = this;

    /**
     * @memberOf module:"7Shop.Bootstrap".IframeBootstrap
     */
    this.isIframeIntegration = function () {
      return $window.ShopEnvironment.getUrlFlag('integration')
        && Modules.isModuleEnabled('iframePubSub');
    };

    /**
     * @memberOf module:"7Shop.Bootstrap".IframeBootstrap
     * @param {Object} config
     * @param {Boolean} config.needsInstallation
     */
    this.boot = function (config) {
      var deffered = $q.defer();
      Modules.loadModule('iframe_pub_sub').then(function () {
        var service = $injector.get('IframePubSub');
        service.addFrame(angular.extend(
          {
            debug: ShopEnvironment.isDebugMode(),
            load: function (event) {
              if (event.data) {
                self.udateAppStates(event.data);
              }

              this.emit({
                action: 'Slave.Loaded',
                data: {
                  needsInstallation: config.needsInstallation
                }
              });
              deffered.resolve();
            }
          },
          GravitySettings.getByKey('module.iframePubSub')
        ));
      }).catch(function (err) {
        deffered.reject(err);
      });

      return deffered.promise;
    };

    /**
     * @memberOf module:"7Shop.Bootstrap".IframeBootstrap
     * @param {Object} data
     * @param {Object} data.connection
     * @param {Boolean} data.connection.valid
     */
    this.udateAppStates = function (data) {
      if (data.connection) {
        $rootScope.$emit('7S:Connection.UpdateState', {
          data: {
            valid: data.connection.valid
          }
        });
      }

      if (data.printer) {
        $rootScope.$emit('7S:Peripherals.UpdatePrinterState', {
          data: data.printer
        });
      }
    };
  }
})();
