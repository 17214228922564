(function () {
  angular.module('7Shop.Core')
    .service('companyService', companyService);

  /**
   * @class companyService
   * @memberof module:"7Shop.Core"
   */
  function companyService(LocalData) {
    /**
     * @typedef {Object} SevenCompany
     * @memberof module:"7Shop.Core".companyService
     * @property {string} currency - Company currency
     * @property {number} fractionDigits
     * @property {number} id
     * @property {string} language
     * @property {string} name
     * @property {string} timezone
     * @property {string} timezoneOffset
     * @property {string} uuid - Unique company id across data centers
     * @property {string} datacenter - Where is company stored
     * @property {string} [jib] - this only available when tax bih company is enabled
     */

    /**
     * @type SevenCompany
     * @memberof module:"7Shop.Core".companyService
     */
    this.data = {};

    /**
     *
     * @typedef {Object} GravityCompanySettings
     * @memberof module:"7Shop.Core".companyService
     * @property {String} [registrationPrefferedTZ=false]
     * @property {String} [registrationPrefferedCountry=false]
     * @property {Boolean} zodiacEnabled=false
     * @property {String} theme=default - one of themes from app/styles/themes
     * @property {Object} betslip
     * @property {String} betslip.payinRounding=false
     * @property {Number} betslip.payoutDecimals=false
     * @property {Boolean} multiProfiles=false
     * @property {String} printTemplate=default
     * @property {Array.<S_Shortcut>} shortcuts
     * @property {Boolean} dateRange=true
     * @property {Boolean} activeShiftLogoutNotify=true - Show notification on logout if shift is active
     * @property {Object} sentry
     * @property {Boolean} sentry.enabled=true
     * @property {Boolean} currency
     * @property {String} currency.prefix=suffix
     */

    /**
     * These are settings coming from gravity api. They are defined
     * under Application Settings module. For more information see {@tutorial gravity-config}
     * Application settings extend from app template settings,
     * app settings will override app template settings.
     *
     * @type GravityCompanySettings
     * @memberof module:"7Shop.Core".companyService
     *
     */
    this.gravitySettings = {};

    /**
     * @memberof module:"7Shop.Core".companyService
     * @param {*} companyData
     */
    this.setData = function (companyData) {
      this.data = companyData;
    };

    /**
     * @memberof module:"7Shop.Core".companyService
     * @param {*} settings
     */
    this.updateData = function (data) {
      angular.extend(this.data, data);
    };

    this.setSettings = function (settings) {
      this.gravitySettings = settings;
    };

    /**
     * @memberof module:"7Shop.Core".companyService
     * @returns {String} returns uuid of company
     */
    this.getUuid = function () {
      return this.data.uuid;
    };

    /**
     *
     * @memberof module:"7Shop.Core".companyService
     * @param name
     * @return {* | boolean}
     */
    this.getSetting = function (name) {
      if (angular.isDefined(this.gravitySettings[name])) {
        return this.gravitySettings[name];
      }
      return false;
    };

    /**
     * @memberof module:"7Shop.Core".companyService
     * @return {string}
     */
    this.getCompanyNameNormalized = function () {
      // eslint-disable-next-line
      return LocalData.betshop.companyName.replace(/[`~!@#$%^&*()_\s|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/š/gi, 's');
    };
  }
})();
