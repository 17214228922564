(function () {
  angular
    .module('7Shop.Bootstrap')
    .factory('gravityInterceptor', gravityInterceptor);

  function gravityInterceptor($injector, LocalData, NabENV, ShopEnvironment, CloudflareService) {
    /**
     * Gets cloudflare route for requested url.
     *
     * @memberOf module:"7Shop.Bootstrap".gravityInterceptor
     * @param {string} url
     * @returns {string}
     */
    const getCloudflareUrl = function (url) {
      /**
       * In moment when bootstrap request is emitted,
       * GravitySettings provider are not loaded yet
       * so we have to put this route to whitelist.
       */
      const whiteList = [
        {
          request: '7platform.info/v1/shop/bootstrap',
          search: NabENV.cloudflare.endpoints.bootstrap.search,
          replace: NabENV.cloudflare.endpoints.bootstrap.replace
        }
      ];
      const baseUrl = CloudflareService.generateCloudflareUrl(NabENV.api.gravity.url);

      for (let i = 0; i < whiteList.length; i += 1) {
        const { request, search, replace } = whiteList[i];
        const isValidRequest = url.includes(request);

        if (isValidRequest) {
          return baseUrl.replace(search, replace);
        }
      }

      return baseUrl;
    };

    /**
     * @memberOf module:"7Shop.Bootstrap".gravityInterceptor
     * @param {string} uri
     * @returns {boolean}
     */
    const isValidUrl = function (uri) {
      const url = uri.toString();
      const urlLength = url.length;
      const baseUrl = getCloudflareUrl(url);
      const baseUrlLength = baseUrl.length;

      return (
        urlLength >= baseUrlLength
        && url.substring(0, baseUrlLength) === baseUrl
      );
    };

    return {
      request: function (request) {
        var LoginData;
        var userService = $injector.get('userService');
        var dc = ShopEnvironment.getDc();
        var lang = ShopEnvironment.getLanguage();

        if (isValidUrl(request.url)) {
          request.headers['X-Nsft-SCD-Dp'] = 'Retail';

          if (!request.headers['X-Nsft-SCD-Version']) {
            request.headers['X-Nsft-SCD-Version'] = '4';
          }

          if (dc) {
            request.headers['X-Nsft-SCD-DC'] = dc;
          }

          if (lang) {
            request.headers['X-Nsft-SCD-Locale'] = lang;
          }

          if (Object.keys(LocalData).length) {
            request.headers['X-Nsft-SCD-Company-Uuid'] = LocalData.betshop.companyUuid;
            request.headers['X-Nsft-SCD-Company-Id'] = LocalData.betshop.companyId;
            request.headers['X-Nsft-SCD-Betshop-Id'] = LocalData.betshop.betshopId;
            request.headers['X-Nsft-SCD-Device-Id'] = LocalData.device.uuid;
            request.headers['X-Nsft-SCD-Device-Uuid'] = LocalData.device.uuid;
            request.headers['X-Nsft-SCD-Device-Token'] = LocalData.device.token;
            request.headers['X-Nsft-SCD-Account-Id'] = LocalData.betshop.companyUuid;
          }

          if (userService.state.loggedIn) {
            LoginData = userService.getData();
            request.headers['X-Nsft-SCD-Locale'] = LoginData.user.language || 'en';
            request.headers['X-Nsft-SCD-DC'] = LoginData.company.datacenter || '';
          }
        }

        return request;
      }
    };
  }
})();
