(function () {
  angular.module('7Shop.Core')
    .factory('ScanSvc', ScanSvc);

  /**
   *
   * @constructor ScanSvc
   */
  function ScanSvc(
    $document,
    $window,
    $rootScope,
    $log,
    KeyMapper,
    KeyEventsSvc,
    NabNotifications,
    Modal,
    GravitySettings
  ) {
    const config = {
      treshold: 30,
      prefix: 'space',
      regex: {
        pattern: /^[A-Za-z0-9.\-%]$/,
        flags: 'i'
      }
    };
    const scanResult = {
      code: '',
      finished: false
    };
    const previousKey = {
      receivedAt: null,
      event: null
    };

    let scanning = false;
    let secureTimerOff = null;
    let scanStarted = null;
    let currentTime = 0;
    let previousEventReceived = Date.now();
    let difference = 0;
    const treshold = 2000;

    /**
     * Check are we in scan mode.
     * It means that something is currently scanned by scanner
     *
     * @memberOf ScanSvc
     * @returns {boolean}
     */
    function inScanMode() {
      return scanning;
    }

    /**
     * Check is scanner giving signal to go into scan mode
     *
     * @memberOf ScanSvc
     * @param {KeyboardEvent} e
     * @return {boolean}
     */
    function goingIntoScan(e) {
      return KeyMapper.space.key === e.keyCode;
    }

    /**
     * Turn on/off scan mode
     * @memberOf ScanSvc
     * @param mode
     */
    function toggleScanMode(mode) {
      // if starting scan mode,
      // set safe timer so we don't get stuck in safe mode
      // (how is this possible? ask dino?)
      if (mode) {
        scanning = true;
        scanStarted = new Date().getTime();
        /* eslint-disable angular/timeout-service */
        secureTimerOff = window.setTimeout(() => {
          scanning = false;
          scanStarted = null;
        }, treshold);

        return;
      }

      scanning = false;
    }

    /**
     * Is scan over. If last key is enter scan is over
     *
     * @memberOf ScanSvc
     * @param {KeyboardEvent} e
     * @return {String | Boolean}
     */
    function isScanModeOver(e) {
      // if enter is hit it is end
      // of scan mode, clear stuff
      // and send ticket id to to check service
      if (e.keyCode === KeyMapper.enter.key) {
        toggleScanMode(false);
        clearTimeout(secureTimerOff);
        return true;
      }

      return false;
    }

    /**
     * Is scan process not getting started and/or it is finished.
     * If can be used to allow user interaction only if this call returns true in resolve.
     *
     * @memberOf ScanSvc
     * @param {KeyboardEvent} e
     * @return {Promise.<boolean>}
     */
    function scanInactiveOrFinished(e) {
      return new Promise((resolve) => {
        const timePast = scanStarted ? (new Date().getTime() - scanStarted) > treshold : true;

        /* eslint-disable angular/timeout-service */
        window.setTimeout(() => {
          const scanInactiveOrOver = timePast && !goingIntoScan(e) && !inScanMode();
          resolve(scanInactiveOrOver);
        }, 100);
      });
    }

    function processKeyEvent(e) {
      const whitelistedKeys = new RegExp(config.regex.pattern, config.regex.flags);
      let tresholdBetweenKeys = config.treshold;
      currentTime = Date.now();
      difference = currentTime - previousEventReceived;
      previousEventReceived = currentTime;

      $log.debug('[7Shop.Core] Scan: Processing code.', {
        code: e.code,
        key: e.key,
        repeat: e.repeat,
        difference,
        currentTime,
        previousEventReceived
      });

      if (scanning) {
        tresholdBetweenKeys += 100;
        $log.debug(`[7Shop.Core] Scan: Update treshold to ${tresholdBetweenKeys}`);
      }

      // Too much difference between characters - which means that this is not scan mode.
      // Also if prefixed based detected, skip this check and go to next line
      if ((difference > tresholdBetweenKeys
        && difference !== currentTime
        && !goingIntoScan(e))
        || e.repeat) {
        $log.debug('[7Shop.Core] Scan: It`s not scan mode. Reseting.', {
          key: e.key,
          keyCode: e.keyCode,
          difference,
          currentTime,
          previousEventReceived
        });
        scanResult.code = '';
        scanResult.finished = false;
        previousKey.receivedAt = currentTime;
        previousKey.event = e;

        return scanResult;
      }

      $log.debug('[7Shop.Core] Scan: Possible scan mode.');

      // prefix based scan detected, enable
      if (goingIntoScan(e)) {
        $log.debug('[7Shop.Core] Scan: Space triggered and scan mode marked as started.');
        scanResult.code = '';
        toggleScanMode(true);
        return scanResult;
      }

      // let's add previous char to list of scanned codes if time passed
      // before previous and current is below treshold
      // this will happen if scan barcode without space prefix
      if (scanResult.code.length === 0
        && !goingIntoScan(e)
        && (previousKey.event && (currentTime - previousKey.receivedAt) < (tresholdBetweenKeys * 2))
        && whitelistedKeys.test(previousKey.event.key)
      ) {
        $log.debug(
          '[7Shop.Core] Scan: Append previous key.',
          previousKey.event.code,
          previousKey.event.key
        );
        scanResult.code += previousKey.event.key;
        previousKey.event = null;
        previousKey.receivedAt = 0;
      }

      if (scanResult.code.length >= 2 && !inScanMode()) {
        $log.debug('[7Shop.Core] Scan: Detected time based mode, set scanning tu true.');
        // if we are here and we have two chars, it means that we are 99% in tim based mode
        // let's set scan mode to true so code below can have latest state,
        // beware that scan alreay started and
        // that scan time is little bit off time when it really started (~20-40ms)
        toggleScanMode(true);
      }

      // prevent native keydown behavior if we detected space
      // or we are in time based scan (at least two chars are added to final result)
      if (inScanMode()) {
        $log.debug('[7Shop.Core] Scan: Prevent keydown default behavior.');
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
      }

      // codes from scaner (e.g we could trigger enter on payin)
      // but we don't know if first char is from scan so don't move focus when first code is entered
      if (inScanMode()
        && $window.document.activeElement?.tagName.toLocaleLowerCase() !== 'body') {
        $log.debug('[7Shop.Core] Scan: Blur from active element.');
        $window.document.activeElement.blur();
      }

      if (whitelistedKeys.test(e.key)) {
        $log.debug('[7Shop.Core] Scan: Adding key.', e.code, e.key);
        scanResult.code += e.key;
        previousKey.event = null;
      }

      if (isScanModeOver(e)) {
        previousEventReceived = 0;

        if (scanResult.code.length) {
          scanResult.finished = true;
          toggleScanMode(false);
        }
      }

      return scanResult;
    }

    function timeBasedScanning(e) {
      const result = processKeyEvent(e);

      if (result.finished) {
        $rootScope.$emit('7S:Scanner.ScanningFinished', {
          callSign: {
            sufix: ''
          },
          type: 'barcode',
          data: {
            code: result.code
          }
        });
        scanResult.code = '';
        scanResult.finished = false;

        return;
      }

      if (!inScanMode() && !goingIntoScan(e)) {
        if ((NabNotifications.current && NabNotifications.current.actions.length > 0)
          || Modal.getModal('ticket-preview')
        ) {
          $log.debug('[7Shop.Core] Scan: Preventing keydown since notification with actions is opened or modal is active.', {
            keyCode: e.keyCode
          });
          return;
        }

        if (shouldPreventKeyDefaultBehavior(e.key)) {
          e.preventDefault();
          $log.debug('[7Shop.Core] Scan: Preventing key default behavior.', {
            key: e.key
          });
        }

        KeyEventsSvc.processKeyEvent(e);
      }
    }

    /**
     * Check if a key can be propagated through the DOM tree.
     * If true, the key won't be added to scan input value.
     *
     * @param {String} key
     * @returns {Boolean}
     */
    function shouldPropagateScanKey(key) {
      const allowedPropagationKeys = [
        'escape',
        '+',
        ...Array(10).fill().map((_, i) => `f${i + 1}`)
      ];

      return allowedPropagationKeys.includes(key?.toLowerCase());
    }

    /**
     * Returns true if default behavior of the key is prevented in CMS
     * pointing out that the key is reserved for other action that client configured
     * @param {String} key
     * @returns {Boolean}
     */
    function shouldPreventKeyDefaultBehavior(key) {
      const preventKeysList = GravitySettings.getModuleDataKeyValue('application', 'preventKeys');

      return !!preventKeysList?.find((preventKey) => preventKey.key === key);
    }

    function setConfig(newConfig) {
      Object.assign(config, newConfig);
    }

    function getConfig() {
      return config;
    }

    function listenForScannerEvents() {
      $document.on('keydown', timeBasedScanning);
    }

    function stopListeningForScannerEvents() {
      $document.off('keydown', timeBasedScanning);
    }

    return {
      listenForScannerEvents,
      stopListeningForScannerEvents,
      setConfig,
      getConfig,
      inScanMode,
      goingIntoScan,
      toggleScanMode,
      isScanModeOver,
      scanInactiveOrFinished,
      shouldPropagateScanKey,
      shouldPreventKeyDefaultBehavior
    };
  }
})();
