/* @ngInject */
function UserApiService(
  $http,
  NabENV
) {
  this.login = function (params) {
    return $http.post(
      NabENV.api.gravity.shop + '/login',
      {
        _username: params.userName,
        _password: params.password
      },
      {
        headers: {
          'X-Nsft-SCD-App-Name': params.appName,
          'X-Nsft-SCD-Betshop-Uuid': params.betshopUuid
        }
      }
    );
  };

  this.checkSession = function (token, url, appName, betshopUuid) {
    return $http.get(url + '/login-check', {
      headers: {
        Authorization: 'Bearer ' + token,
        'X-Nsft-SCD-App-Name': appName,
        'X-Nsft-SCD-Betshop-Uuid': betshopUuid
      }
    });
  };

  this.logOut = function () {
    return $http.get(NabENV.api.gravity.shop + '/logout', {
      headers: {
        'X-Nsft-SCD-Version': '2'
      }
    });
  };
}

export default UserApiService;
