import translate from 'angular-translate';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import '@nsftx/seven-ng-print-service/dist/ng-print-service';
import ngLib from '../../modules/ng_lib';
import PeripheralsModule from '..';
// eslint-disable-next-line import/no-cycle
import ShopModules from '../../modules';
import UserModule from '../../user';
import coreModule from '../../core';
import commonModule from '../../common';
import ShopPrint from './shopPrintService';
import listener from './printListenersService';
import sPrinterStatus from './sPrinterStatusComponent';

/**
 * @module "7Shop.Print"
 */
const modulName = angular.module('7Shop.Print', [
  translate,
  ngLib,
  'nabPrintService',
  'SevenClient.Config',
  'SevenClientCore',
  PeripheralsModule,
  commonModule,
  coreModule,
  ShopModules,
  UserModule
]).config(function ($provide) {
  // keep decorator here for now till
  // we move nabPrintService to lazy load with integrations/nps
  $provide.decorator(
    'printServiceApi',
    function printServiceApiDecorator(
      $q,
      $delegate,
      userService,
      LocalStorage,
      PeripheralsSvc,
      GravitySettings
    ) {
      /**
       *
       * @param {Object} config
       * @param {Object} config.selectedPrinter
       */
      $delegate.init = function (config) {
        var storedPrintTemplate;
        var loginData = userService.getData();
        var printTemplate = GravitySettings.getModuleDataKeyValue('config', 'printTemplate');

        return $q(function (resolve) {
          LocalStorage.getItem('settings.printTemplate')
            .then(function (response) {
              if (response) {
                response = JSON.parse(response);
                storedPrintTemplate = response.template;
              }

              $delegate.initializeService(
                config.selectedPrinter,
                loginData.user.language
              ).finally(function () {
                PeripheralsSvc.updatePeripheralStatus('PRINTER', {
                  valid: $delegate.current.Status.valid,
                  statusLevel: $delegate.current.Status.code,
                  errorMessage: $delegate.current.Status.message
                });
              });

              if (storedPrintTemplate || printTemplate) {
                $delegate.updatePrintTemplate(storedPrintTemplate || printTemplate);
              }

              resolve();
            }).catch(function () {
              resolve();
            });
        });
      };

      return $delegate;
    }
  );
})
  .factory('ShopPrintService', ShopPrint)
  .service('PrintListeners', listener)
  .component('sPrinterStatus', sPrinterStatus)
  .run(function (
    $rootScope,
    $log,
    $window,
    $translate,
    ShopPrintService,
    PrintListeners,
    printServiceApi,
    printEngineProvider,
    GravitySettings,
    NabENV,
    userService,
    PeripheralsSvc,
    errorParser
  ) {
    PrintListeners.setPrinterListeners();
    PeripheralsSvc.register({
      id: 'PRINTER',
      validate: ShopPrintService.getPrinterStatus,
      valid: false,
      errorMessage: $translate.instant('settings.print_not_available'),
      statusLevel: 'unknown'
    });

    $rootScope.$on('7S:Peripherals.PrinterReady', function (e, data) {
      var currencyPrintValue = GravitySettings.getModuleDataKeyValue('currency', 'printValue');
      var printServiceIp = $window.localStorage.getItem('settings.printServiceIp');
      var loginData = userService.getData();

      PrintListeners.init();

      // in transition period betwee sps and nps, printEngineProvider must be always intilized and updated
      angular.extend(printEngineProvider, {
        url: printServiceIp ? 'http://' + printServiceIp + ':8080/' : NabENV.settings.printService.url,
        autoSetup: false,
        pinRequired: loginData.ticketActions && loginData.ticketActions.pinRequred,
        pinVisible: GravitySettings.getModuleDataKeyValue('config', 'pinVisible'),
        currencyPosition: GravitySettings.getModuleDataKeyValue('print', 'currencyPosition'),
        currency: currencyPrintValue !== undefined ? currencyPrintValue
          : loginData.company && loginData.company.currency,
        confirmationTimeFormat: GravitySettings.getModuleDataKeyValue('print', 'confirmationTimeFormat')
      });

      if (data.driverState.type === 'sps') {
        // in transition period betwee sps and nps, nps service must be always intilized
        printServiceApi.init({
          selectedPrinter: data.selectedPrinter
        }).catch(function (err) {
          $log.error('[7Shop.Print] Print service driver init failed', {
            code: 'S_PRINT_DRIVER_SWITCH_ERR',
            ...errorParser.parseUpstream(err),
            driver: data.driver.type
          });
        });
      }
    });
  }).name;

export default modulName;
