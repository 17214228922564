import { defaults } from 'underscore';
import modalTemplate from './modal.html';
import modalTouchTemplate from './touch/modal.html';

(function () {
  angular.module('7Shop.Common')
    .service('Modal', Modal);

  /**
   * @class Modal
   *  @memberOf module:"7Shop.Common"
   */
  function Modal($templateCache, modalSvc, Touch) {
    function getTemplate() {
      return Touch.isTouch()
        ? modalTouchTemplate : modalTemplate;
    }

    /**
     * @param {Object} config
     * @param {Object} config.templateUrl
     * @param {Object} config.controller
     * @param {Object} config.inputs.templateUrl
     * @param {Object} config.inputs.title
     * @param {Object} config.id
     */
    this.show = function (config) {
      config = config || {};
      config.inputs = config.inputs || {};
      defaults(config.inputs, {
        title: false,
        id: config.id,
        templateUrl: false,
        showClose: true,
        api: {}
      });
      defaults(config, {
        controller: 'ModalController',
        templateUrl: getTemplate(),
        callback: angular.noop
      });

      $templateCache.put(config.id, config.inputs.component);

      modalSvc.showModal(config)
        .then(config.callback);
    };

    this.getModal = function (id) {
      return modalSvc.getModal(id);
    };

    this.closeAll = function () {
      modalSvc.closeAll();
    };

    this.closeModalById = function (id) {
      const modal = this.getModal(id);
      if (modal && modal.scope) {
        modal.scope.close();
      }
    };
  }
})();
