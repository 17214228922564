import { isString } from 'underscore';

/* @ngInject */
export default function LogDecorator(
  $delegate,
  $injector,
  $window,
  GravitySettings,
  uuid
) {
  const logLevels = {
    error: 1,
    warn: 2,
    info: 3,
    debug: 4
  };
  const NabENV = $injector.get('NabENV');
  const getLogLevel = () => {
    const url = new URL($window.location.href);
    const logLevel = logLevels[url.searchParams.get('logLevel')]
        || logLevels[localStorage.getItem('settings.logLevel')]
        || logLevels[GravitySettings.getModuleDataKeyValue('application', 'logLevel')]
        || logLevels.warn;
    return logLevel;
  };
  const blacklistMessages = [
    '[NgPrintService] selectPrinter startPrinterChecker failed',
    '[NgPrintService] selectPrinter stop checker failed'
  ];

  const sendMessage = (params, level) => {
    const UserService = $injector.get('userService');
    const LocalData = $injector.get('LocalData');
    const logService = $injector.get('logService');
    const [message, ...rest] = Array.isArray(params) ? params : [params];
    let restParams;
    let finalMessage = message;
    const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
    const localTime = (new Date(Date.now() - timeZoneOffset));

    if (rest?.length && rest[0]) {
      // check is this object?
      if (Object.getPrototypeOf(rest[0]) === Object.prototype) {
        // Destructure every property and send it separately
        ({ ...restParams } = rest[0]);
      } else if (isString(rest[0])) {
        // if you call $exceptionHandler('Error', 'Wrong input'),
        // 'Wrong input' will be set as cause
        ({ ...restParams } = { cause: rest[0] });
      }
    }

    // AngularJS can throw message as error instance,
    // one example is ngRepeat:dupes
    // if we don't take message as string, graylog will show empty object
    if (message instanceof Error) {
      finalMessage = message.message;
    }

    const log = {
      message: finalMessage,
      level,
      appName: '7Shop',
      channel: '7shop',
      timestamp: localTime.toISOString(),
      log_id: uuid.v4(), // to filter duplicate logs sent to graylog
      tenant_id: LocalData.betshop?.companyUuid,
      tenant_name: LocalData.betshop?.companyName,
      device_id: LocalData.device?.uuid,
      device_name: LocalData.device.name,
      betshop_id: LocalData.betshop?.uuid,
      betshop_name: LocalData.betshop?.name,
      operater_id: UserService.getData()?.user?.uuid || '',
      version: NabENV.version,
      http_user_agent: $window.navigator.userAgent,
      ...restParams
    };

    if (blacklistMessages.indexOf(message) !== -1) {
      return;
    }

    logService.logs.push(log);
    if (logService.logs.length >= NabENV.graylog.messageLimit) {
      logService.sendLogsAndClearLogsQueue()
        .catch(() => {
          $delegate.error('[7Shop.Logger] Send logs on batch failed', {
            code: 'S_LOGGER_SEND_BATCH_ERR'
          });
        });
    }
  };

  const swapLog = function (originalFn, level) {
    return function () {
      const logService = $injector.get('logService');
      if (getLogLevel() >= logLevels[level] && logService.isLogStreamEnabled()) {
        sendMessage([...arguments], level);
      }
      originalFn.apply(null, arguments);
    };
  };

  $delegate.error = swapLog($delegate.error, 'error');
  $delegate.warn = swapLog($delegate.warn, 'warn');
  $delegate.info = swapLog($delegate.info, 'info');
  $delegate.debug = swapLog($delegate.debug, 'debug');

  return $delegate;
}
