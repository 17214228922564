/* @ngInject */
function sGaTrackEvent(googleAnalyticsService, $parse, $log) {
  return {
    restrict: 'A',
    scope: true,
    link: (scope, element, attributes) => {
      element.on('click', () => {
        const eventName = attributes.sGaTrackEvent;
        let eventParams = {};
        try {
          eventParams = $parse(attributes.gaEventParams)(scope);
        } catch (err) {
          $log.error(`[7Shop] Invalid event parameters format: ${err.message}`, {
            code: 'S_GA_INVALID_EVENT_PARAMETERS_FORMAT'
          });
        }
        googleAnalyticsService.trackEvent(eventName, eventParams);
      });
    }
  };
}

export default sGaTrackEvent;
