/* @ngInject */
function GravityGatewayTickets(
  $log,
  $injector,
  SevenGamesSvc
) {
  const LOG_PREFIX = '[7S.Integrator]';
  const onPayout = (slaveData) => {
    const { data, resolve, reject } = slaveData;
    const TicketService = $injector.get('TicketService');
    const newTicket = TicketService.createTicket(data.ticket);

    $log.info(`${LOG_PREFIX} Tickets.Payout event received`, {
      code: 'S_INTEGRATOR_TICKETS_PAYOUT_EVENT_RECEIVED',
      product_displayid: newTicket.product,
      request_id: newTicket.requestUuid,
      ticket_code: newTicket.getDisplayId()
    });

    newTicket.update(data.ticket);

    // update local pin if ticketPin set
    // we must persist ticketPin when message schange occurs
    // e.g. we sent ticketPin in Tickets.Checked event
    // and we expect it back under ticketPin property
    if (newTicket.ticketPin) {
      newTicket.setLocalPin(newTicket.ticketPin);
    }

    TicketService.payoutTicket(newTicket).then((result) => {
      const { code, message, ticket } = result;
      if (code) {
        // error
        if (reject) {
          reject(result);
        }
        $log.error(`${LOG_PREFIX} Payout rejected to slave`, {
          data: slaveData.data,
          product_displayid: ticket.product,
          request_id: ticket.requestUuid,
          ticket_code: ticket.getDisplayId(),
          upstream_code: code,
          upstream_message: message,
          code: 'S_INTEGRATOR_TICKETS_PAYOUT_ERR'
        });
      } else if (resolve) {
        resolve(result);
        $log.info(`${LOG_PREFIX} Payout resolved to slave`, {
          request_id: ticket.requestUuid,
          product_displayid: ticket.product,
          ticket_code: ticket.getDisplayId(),
          code: 'S_INTEGRATOR_PAYOUT_SUCCESS'
        });
      }
    });
  };

  const onTicketCancel = (slaveData) => {
    const { data, resolve, reject } = slaveData;
    const TicketService = $injector.get('TicketService');
    const newTicket = TicketService.createTicket(data.ticket);

    $log.info(`${LOG_PREFIX} Tickets.Cancel event received`, {
      code: 'S_INTEGRATOR_TICKETS_CANCEL_EVENT_RECEIVED',
      product_displayid: newTicket.product,
      request_id: newTicket.requestUuid,
      ticket_code: newTicket.getDisplayId()
    });

    newTicket.update(data.ticket);

    // update local pin if ticketPin set
    // we must persist ticketPin when message schange occurs
    // e.g. we sent ticketPin in Tickets.Checked event
    // and we expect it back under ticketPin property
    if (newTicket.ticketPin) {
      newTicket.setLocalPin(newTicket.ticketPin);
    }

    TicketService.cancelTicket(newTicket).then((result) => {
      const { code, message, ticket } = result;
      if (code) {
        // error
        if (reject) {
          reject(result);
        }
        $log.error(`${LOG_PREFIX} Cancel rejected to slave`, {
          data: slaveData.data,
          product_displayid: ticket.product,
          request_id: ticket.requestUuid,
          ticket_code: ticket.getDisplayId(),
          upstream_code: code,
          upstream_message: message,
          code: 'S_INTEGRATOR_TICKETS_CANCEL_ERR'
        });
      } else if (resolve) {
        resolve(result);
        $log.info(`${LOG_PREFIX} Cancel resolved to slave`, {
          request_id: ticket.requestUuid,
          product_displayid: ticket.product,
          ticket_code: ticket.getDisplayId(),
          code: 'S_INTEGRATOR_CANCEL_SUCCESS'
        });
      }
    });
  };

  const onTicketCopy = (slaveData) => {
    const { data, resolve } = slaveData;
    const ticket = data.ticket;
    const TicketService = $injector.get('TicketService');
    const TicketActions = $injector.get('TicketActions');
    const activeGame = SevenGamesSvc.getGame(ticket.product);
    const newTicket = TicketService.createTicket(ticket);

    $log.info(`${LOG_PREFIX} Tickets.Action copy event received`, {
      code: 'S_INTEGRATOR_TICKETS_PRINT_COPY_EVENT_RECEIVED',
      product_displayid: newTicket.product,
      request_id: newTicket.requestUuid,
      ticket_code: newTicket.getDisplayId()
    });

    ticket.translation = activeGame.translation;
    TicketActions.printTicketCopy(ticket);
    if (resolve) resolve();
  };

  /**
   * @deprecated Use Tickets.Cancel and Tickets.Payout
   * @param {object} slaveData
   */
  const onTicketAction = (slaveData) => {
    const possibleActions = {
      ticketPayout: onPayout,
      ticketCancel: onTicketCancel,
      ticketCopy: onTicketCopy
    };
    Object.keys(possibleActions).forEach(function (actionName) {
      const action = possibleActions[actionName];
      if (actionName === slaveData.data.action.id) {
        action(slaveData);
      }
    });
  };

  const onValidateCancel = (slaveData) => {
    const { data, resolve, reject } = slaveData;
    const TicketValidationService = $injector.get('TicketValidationService');
    TicketValidationService.validateCancel({ ticket: data.ticket }).then(() => {
      if (resolve) {
        resolve({
          ticket: data.ticket
        });
      }
    }).catch((error) => {
      if (reject) {
        reject(error);
      }
    });
  };

  const onValidatePayout = (slaveData) => {
    const { data, resolve, reject } = slaveData;
    const TicketValidationService = $injector.get('TicketValidationService');
    TicketValidationService.validatePayout({ ticket: data.ticket }).then(() => {
      if (resolve) {
        resolve({
          ticket: data.ticket
        });
      }
    }).catch((error) => {
      if (reject) {
        reject(error);
      }
    });
  };

  const onSlaveTicketAction = (slaveData) => {
    switch (slaveData.action) {
    case 'Tickets.Payout':
      onPayout(slaveData);
      break;
    case 'Tickets.Cancel':
      onTicketCancel(slaveData);
      break;
    case 'Tickets.Action':
      onTicketAction(slaveData);
      break;
    case 'Tickets.ValidatePayout':
      onValidatePayout(slaveData);
      break;
    case 'Tickets.ValidateCancel':
      onValidateCancel(slaveData);
      break;
    default:
      $log.error(`[7Shop.Integrator] No handler for action ${slaveData.action}`, {
        code: 'S_INTEGRATOR_TICKETS_NO_HANDLER'
      });
      break;
    }
  };

  return {
    onSlaveTicketAction
  };
}

export default GravityGatewayTickets;
