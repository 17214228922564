/**
 *
 * @class PeripheralsSvc
 * @memberOf module:"7Shop.Peripherals"
 */
/* @ngInject */
function PeripheralsSvc($q) {
  return {

    /**
     * @memberof module:"7Shop.Peripherals".PeripheralsSvc
     * @type {Array.<{id: string, validate: function}>}
     */
    list: [],

    /**
     *
     * Register peripherals
     *
     * @memberof module:"7Shop.Peripherals".PeripheralsSvc
     * @param {Object} peripheral
     * @param {String} peripheral.id
     * @param {Boolean} peripheral.valid
     * @param {Function} peripheral.validate
     * @param {String} peripheral.errorMessage
     * @param {String} peripheral.statusLevel
     * @return {Object}
     */
    register: function (peripheral) {
      return this.list[this.list.push(peripheral) - 1];
    },

    /**
     * Get peripheral
     *
     * @memberof module:"7Shop.Peripherals".PeripheralsSvc
     * @param {String} id
     * @return {false | {id: string, validate: function}}
     */
    getPeripheral: function (id) {
      return this.list.find(function (el) {
        return el.id === id;
      });
    },

    /**
     * Update peripheral state
     *
     * @memberof module:"7Shop.Peripherals".PeripheralsSvc
     * @param {String} id - Id of peheiperal.
     * @param {Object} newState - New state.
     */
    updatePeripheralStatus: function (id, newState) {
      var peripheral = this.getPeripheral(id);
      angular.extend(peripheral, newState);
    },

    /**
     * @memberof module:"7Shop.Peripherals".PeripheralsSvc
     * @param {Object} params
     * @param {Array.<String>} params.validators - List of peripehrals id's which will be validated
     */
    validate: function (params) {
      var promises = [];

      this.list.forEach(function (peripheral) {
        if (params.validators.indexOf(peripheral.id) >= 0) {
          promises.push(peripheral.validate(params));
        }
      });

      return $q.all(promises).catch(function (err) {
        return $q.reject({
          errorMsg: err.message,
          errors: []
        });
      });
    }
  };
}

export default PeripheralsSvc;
