(function () {
  /* eslint-disable no-multi-assign */
  var ShopDeviceDataLoaders = window.ShopDeviceDataLoaders = window.ShopDeviceDataLoaders || {};
  var injector = angular.injector(['ng']);
  var $http = injector.get('$http');
  var $q = injector.get('$q');

  ShopDeviceDataLoaders.sevenDeviceProfileLoader = {

    /**
     * Is device installed
     * by searching for data in local storage or from nar.
     *
     */
    isInstalled: function () {
      var defer = $q.defer();
      var data = localStorage.getItem('device.data');
      var profileUuid = window.ShopEnvironment.getUrlFlag('profileUuid');

      if (data) {
        defer.resolve(data);
      } else {
        $http({
          url: 'http://127.0.0.1:8081/device_profiles',
          method: 'GET'
        }).then(function (response) {
          if (response.data && response.data[profileUuid]) {
            defer.resolve(response.data[profileUuid]);
          } else {
            defer.reject();
          }
        }).catch(function () {
          defer.reject();
        });
      }

      return defer.promise;
    },

    /**
     * Get device data when shop is in slave mode
     * Data will be in local storage or if that fails fallback to nar api.
     */
    getData: function () {
      var defer = $q.defer();
      var data = localStorage.getItem('device.data');
      var profileUuid = window.ShopEnvironment.getUrlFlag('profileUuid');
      var parsedData;
      var profileData;

      if (data) {
        parsedData = JSON.parse(data);
        defer.resolve({
          appConfig: {
            data: {
              sevenSocketUrl: parsedData.sevenSocketUrl,
              sevenUrl: parsedData.sevenUrl
            }
          },
          device: {
            data: parsedData
          }
        });
      } else {
        $http({
          url: 'http://127.0.0.1:8081/device_profiles',
          method: 'GET'
        }).then(function (response) {
          if (response.data && response.data[profileUuid]) {
            profileData = response.data[profileUuid];
            defer.resolve({
              appConfig: {
                data: {
                  sevenUrl: profileData.sevenUrl,
                  sevenSocketUrl: profileData.sevenSocketUrl
                }
              },
              device: {
                data: profileData
              }
            });
          } else {
            defer.reject();
          }
        });
      }

      return defer.promise;
    }
  };
})();
