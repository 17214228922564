(function () {
  angular.module('7Shop.Bootstrap')
    .factory('tokenInterceptor', tokenInterceptor);

  function tokenInterceptor(
    NabENV,
    SevenRoutes,
    CloudflareService
  ) {
    function isValidUrl(url) {
      var whiteList = [
        CloudflareService.generateCloudflareUrl(SevenRoutes.mainUrl),
        CloudflareService.generateCloudflareUrl(NabENV.api.gravity.url),
        CloudflareService.generateCloudflareUrl(NabENV.reporting.url),
        'https://services-de.7platform.com',
        'https://services.7platform.com',
        'https://services-ro2.7platform.com',
        'https://services-ro.7platform.com',
        'https://services-rs2.7platform.com',
        'https://services-rs.7platform.com',
        'https://services-staging.7platform.com',
        'https://menhir.de.nsoftcdn.com',
        'https://menhir.gb.nsoftcdn.com',
        'https://menhir.ro-2.nsoftcdn.com',
        'https://menhir.ro.nsoftcdn.com',
        'https://menhir.rs-2.nsoftcdn.com',
        'https://menhir.rs.nsoftcdn.com',
        'https://menhir.staging.gb.nsoftcdn.com',
        CloudflareService.generateCloudflareUrl('https://services-de.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://services.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://services-ro2.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://services-ro.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://services-rs2.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://services-rs.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://services-staging.7platform.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.de.nsoftcdn.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.gb.nsoftcdn.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.ro-2.nsoftcdn.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.ro.nsoftcdn.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.rs-2.nsoftcdn.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.rs.nsoftcdn.com'),
        CloudflareService.generateCloudflareUrl('https://menhir.staging.gb.nsoftcdn.com')
      ];
      var taxAuthUrl = url.indexOf('tax-authority') !== -1;
      var i;
      var whiteListedUrl;

      for (i = 0; i < whiteList.length; i += 1) {
        whiteListedUrl = whiteList[i];
        if (url.substring(0, whiteListedUrl.length) === whiteListedUrl) {
          return true;
        }
      }

      return taxAuthUrl || false;
    }

    return {
      request: function (request) {
        if (isValidUrl(request.url)) {
          if (sessionStorage.shopToken && !request.headers.Authorization) {
            request.headers.Authorization = 'Bearer ' + sessionStorage.shopToken;
          }

          return request;
        }

        return request;
      }
    };
  }
})();
