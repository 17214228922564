import angular from 'angular';
import 'angular-uuid';
import userModule from '../../user';
import coreModule from '../../core';
import LogDecorator from './logDecorator';
import logService from './logService';
import runBlock from './run';

/**
 * @module "7Shop.Logger"
 */
const logger = angular.module('7Shop.Logger', [
  userModule,
  coreModule,
  'angular-uuid'
])
  .decorator('$log', LogDecorator)
  .service('logService', logService)
  .run(runBlock)
  .name;

export default logger;
