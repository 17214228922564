import './app';
import './datepicker/dateSelectedDirective';
import './datepicker/datepickerService';
import './datepicker/sDatepickerDirective';
import './datepicker/touch/dateRangeSelectedDirective';
import './dialog/sDialogDirective';
import './directives/copyToClipboardDirective';
import './directives/customDateInput';
import './directives/disableClickDirective';
import './directives/disableKeyboard';
import './directives/dropdownInput/dropdownInputDirective';
import './directives/dropdownModal/dropdownModal';
import './directives/focusFirstInputDirective';
import './directives/gallery/galleryDirective';
import './directives/inputRestoreValueDirective';
import './directives/isDuplicateDirective';
import './directives/multiSelect/multiSelectDirective';
import './directives/multiSelect/multiSelectInput/multiSelectInputDirective';
import './directives/multiSelect/multiSelectItems/multiSelectItemsDirective';
import './directives/multiSelect/multiSelectItems/multiSelectItemsModal/multiSelectItemsController';
import './directives/onlyNumbersDirective';
import './directives/numberDelimiter';
import './directives/overlayNotification/overlayNotification';
import './directives/rightClickDirective';
import './directives/scrollableDirective';
import './directives/sInputSwitch/sInputSwitchDirective';
import './filter/trustHtmlFilter';
import './hooks/hooksManagerService';
import './modal/modalController';
import './modal/modalService';
import './page_nav/sPageNavComponent';
import './services/barcodeService';
import './services/commonService';
import './services/keyMapperConstants';
import './services/localStorage';
import './services/touchProvider';
import './tabs/sTabsComponent';
import './tabs/sTabsPaneComponent';
import './widget/widgetService';
import './helpers/helpersService';

export default '7Shop.Common';
