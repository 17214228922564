/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* @ngInject */
function SevenNcmSvc(
  $timeout,
  $q,
  $log,
  nabSocket,
  SevenGamesSvc,
  nabMessaging
) {
  let conn;
  const connection = {
    isConnected: false,
    lastTimeAlive: null
  };
  let connToken = '';
  let username = '';
  let userLang = '';
  let deliveryPlatform = '';
  let deviceUuid = '';
  const additionalChannels = {};
  let channels = {};
  let connAliveTimeout = null;
  const settings = {
    connectionCheckerUrl: 'http://localhost:5051/get_status'
  };
  const pingName = 'table';
  const pongName = 'tennis';

  /**
   * Game data related messages get broadcasted
   * further to appropriate controllers
   * @param game
   * @param message
   */
  var publishToClient = function (game, message) {
    var foundGame = SevenGamesSvc.getGame(game);

    if (foundGame && foundGame.handleIncomingMessage) {
      // game should implement handleIncomingMessage method
      foundGame.handleIncomingMessage({
        game: game,
        data: message.data,
        type: message.type,
        sentTime: message.sentTime
      });
    } else {
      nabMessaging.publish('NCM:' + message.type, {
        productId: game,
        data: message
      });
    }
  };

  /**
   * On every serviceAlive message from a game we need to
   * update lastTimeAlive property for keeping track
   * about connection to game which can be interrupted
   * regardless of main NCM connection status.
   *
   * LuckySix doesn't need this
   *
   * @param gameId - if no game is passed, update main connection property
   */
  var refreshLastTimeAlive = function (gameId) {
    if (gameId) {
      channels[gameId].lastTimeAlive = new Date().getTime();
    } else {
      connection.lastTimeAlive = new Date().getTime();
    }
  };

  /**
   * Check the last time NCM responded with a pong message
   * If more than 20 seconds passed, consider the connection lost
   */
  var calculateSCMConnStatus = function () {
    connection.isConnected = !(new Date().getTime() - connection.lastTimeAlive > 20000);
  };

  /**
   * Every 15 seconds check is connection alive
   * to update connection status
   * This can also be used on demand
   */
  var checkConnectionAlive = function () {
    var timeoutPeriod = 15000;
    $timeout.cancel(connAliveTimeout);

    conn.emit(pingName);
    calculateSCMConnStatus();

    // If connection is lost, decrease timeout to 7 secs
    if (!connection.isConnected) {
      timeoutPeriod = 7000;
    }

    connAliveTimeout = $timeout(checkConnectionAlive, timeoutPeriod);
  };

  var handleChannelMessage = function (game, message) {
    switch (message.type) {
    case 'newConfig':
      message.game = game;
      nabMessaging.publish('NCM:gameConfigChanged', message);
      break;
    case 'TerminalSettingsChanged':
      nabMessaging.publish('app:configChanged', message.data);
      break;
    case 'deviceTokenInvalidated':
      nabMessaging.publish('device:tokenInvalidated', message.data);
      break;
    case 'serviceAlive':
      refreshLastTimeAlive(game);
      break;
    /* when something happens to live or prematch service and we try to contact them,
      we get this message back - need to subscribe again */
    case 'serviceReconnect':
      conn.subscribeChannel(channels[game].id, channels[game].subscribeConfig.subChannels);
      break;
    case 'balanceUpdate':
      nabMessaging.publish('NCM:balanceUpdated', message);
      break;
    case 'disabledUser':
      nabMessaging.publish('NCM:disabledUser', message);
      break;
    case 'Operator.RoleChanged':
      nabMessaging.publish('Operator.RoleChanged', message);
      break;
    case 'betshopRolesChanged':
      nabMessaging.publish('NCM:betshopRolesChanged', message);
      break;
    case 'passwordResetForced':
      nabMessaging.publish('player:passwordResetForced', message);
      break;
    case 'fiscalTicket':
      nabMessaging.publish('betshop:fiscalTicket', message);
      break;
    case 'fiscalMoneyTransfer':
      nabMessaging.publish('betshop:fiscalMoneyTransfer', message);
      break;
    case 'foreignPayoutAuthorization':
      nabMessaging.publish('NCM:foreignPayoutAuthorization', message);
      break;
    case 'stakeLimitReached':
      nabMessaging.publish('betshop:limitReached', message);
      break;
    case 'stakeLimitRemoved':
      nabMessaging.publish('betshop:limitRemoved', message);
      break;
    case 'seven.maintenance.window_was_created':
    case 'seven.maintenance.window_was_updated':
    case 'seven.maintenance.message_was_created':
    case 'seven.maintenance.message_was_updated':
      nabMessaging.publish('Maintenance.Update', message);
      break;
    case 'notificationUpdate':
      nabMessaging.publish('notificationUpdate', message);
      break;
    case 'operatorLoggedSuccessfullyWithGravityAuthenticator':
      nabMessaging.publish('Operator.Authenticated', message.data);
      break;
    case 'state':
      nabMessaging.publish('NCM:' + message.channelName + '.state', message.data);
      break;
    default:
      publishToClient(game, message);
      break;
    }
  };

  /**
   * Check if last alive message was more than 20 seconds ago
   * If so, do subscription again with existing subscribeConfig
   */
  var checkIsServiceAlive = function (game) {
    if (channels[game].lastTimeAlive == null) {
      return;
    }

    if (new Date().getTime() - channels[game].lastTimeAlive > 20000) {
      conn.subscribeChannel(channels[game].id, channels[game].subscribeConfig.subChannels);
    }
  };

  /**
   * Every ten seconds client sends serviceAlive
   * message to backend services
   * While on it, check is backend service still alive
   */
  var sendClientAlive = function (game) {
    checkIsServiceAlive(game);

    conn.emit('message', { channel: channels[game].id, type: 'serviceAlive', data: {} });

    if (channels[game].aliveTimeout != null) {
      $timeout.cancel(channels[game].aliveTimeout);
    }

    // NOTE: excluded from digest cycle
    channels[game].aliveTimeout = $timeout(sendClientAlive.bind(null, game), 10000, false);
  };

  /**
   * Remove listeners, stop alive timeouts
   */
  var onDisconnect = function () {
    var game;
    var channel;
    $log.error('[SevenClientCore] SevenNcm:Disconnect', {
      code: 'S_SCM_DISCONNECT'
    });

    $timeout.cancel(connAliveTimeout);

    connection.isConnected = false;
    for (game in channels) {
      conn.removeListener(channels[game].id);
      $timeout.cancel(channels[game].aliveTimeout);
      channels[game].aliveTimeout = null;
      channels[game].lastTimeAlive = null;
    }

    for (channel in additionalChannels) {
      conn.removeListener(additionalChannels[channel]);
    }
  };

  /**
   * Remove channel from subscription
   * Cancel active timeouts if any
   *
   * @param {Object} channel
   *
   *  {
   *      id:'O7C58E6F-E035-4DD6-B149-1933DF72C849',
   *      product: 'LiveBetting
   *  }
   */
  var unSubscribeChannel = function (channel) {
    // Remove 'serviceAlive' timeouts
    if (channel.product && channels[channel.product]) {
      $timeout.cancel(channels[channel.product].aliveTimeout);
      channels[channel.product].aliveTimeout = null;
    }

    conn.removeListener(channel.id);
    conn.emit('unsubscribe', channel.id);
  };

  /**
   * Subscribe game and set on listener
   *
   * @param {Object} game
   *
   *   {
   *        "id": "LiveBetting",
   *        'subscribeConfig': {
   *                "channel": "6f70074d-28f5-4941-89ba-2199d794cb9f",
   *                "subChannels": {
   *                    "language": "de",
   *                    "deliveryPlatform": "Terminal",
   *                    "deviceUuid": "07C58E6F-E035-4DD6-B149-1933DF72C849"
   *            }
   *        }
   *    }
   *
   */
  var subscribeGameChannel = function (game) {
    var serviceAliveGames = ['LiveBetting', 'PreMatchBetting',
      'SportradarVTO', 'SportradarVFL', 'SportradarVBL', 'SportradarVirtualFootball'];
    $log.debug('[SevenClientCore] Subscribed to ' + game.id);

    /**
     * Array of games which require serviceAlive messages
     */
    conn.emit('subscribe', game.subscribeConfig);

    channels[game.id].subscribeConfig = game.subscribeConfig;

    if (serviceAliveGames.indexOf(game.id) !== -1) {
      // If there is already a timeout left from
      // previous subscription, cancel it
      if (channels[game.id].aliveTimeout != null) {
        $timeout.cancel(channels[game.id].aliveTimeout);
      }

      // must not send it immediately after subscribe, it will fail miserably
      // NOTE: excluded from digest cycle
      channels[game.id].aliveTimeout = $timeout(sendClientAlive.bind(null, game.id), 2000, false);
    }

    if ((settings.optimized && game.id === 'LiveBetting') || game.id === 'PreMatchBetting') {
      conn.on(
        game.subscribeConfig.channel,
        handleChannelMessage.bind(null, game.id),
        false
      );
    } else {
      conn.on(game.subscribeConfig.channel).then(
        angular.noop,
        angular.noop,
        handleChannelMessage.bind(null, game.id)
      );
    }
  };

  /**
 * After connection is established, we need to subscribe
 * to all channels and add listeners for their messages
 * messages are received through update callback
 */
  var registerChannels = function () {
    var game;
    var subscribeConfig;
    var obj;
    for (game in channels) {
      obj = channels[game];

      // merge subscribeConfig config into default config
      subscribeConfig = angular.merge(
        {
          channel: obj.id, // this cpvUuid
          subChannels: {
            language: userLang,
            deliveryPlatform: deliveryPlatform,
            deviceUuid: deviceUuid
          }
        },
        obj.subscribeConfig
      );

      // subscribe game channel
      subscribeGameChannel(
        {
          id: game, // game id
          subscribeConfig: subscribeConfig
        }
      );
    }
  };

  var registerAdditionalChannels = function () {
    Object.keys(additionalChannels).forEach(function (channel) {
      conn.subscribeChannel(additionalChannels[channel], { language: userLang, deliveryPlatform: deliveryPlatform });
      conn.on(additionalChannels[channel]).then(angular.noop, angular.noop, function (message) {
        // this is an NCM bug, they shouldn't be sending state here... remove this when they fix it
        if (message.type === 'state') {
          message.channelName = channel;
        }
        handleChannelMessage('', message);
      });
    });
  };

  var onConnectionEstablished = function () {
    connection.isConnected = true;
    registerChannels();
    registerAdditionalChannels();
    connection.lastTimeAlive = new Date().getTime();

    if (settings.useScmPingPong) {
      connAliveTimeout = $timeout(checkConnectionAlive, 9000, false);
    }
  };

  /**
   *
   * Send message to product
   *
   * @param {Object} message
   *
   * {
   *       channel:$cpvUuid,
   *       type:$messageName,
   *       data: {
   *           $prop1: $data1,
   *           $prop2: $data2
   *       }
   *   }
   *
   * @returns {Socket|Emitter|*}
   */
  var sendMessage = function (message) {
    return conn.emit('message', {
      channel: message.channel,
      type: message.type,
      data: message.data
    });
  };

  return {

    /**
     * Status of connection
     */
    conn: connection,

    /**
     * On login NCM service gets populated with necessary data
     * such as - all channels we need to subscribe to and
     * identification data needed by backend services
     *
     * @param {Object} data - Settings for connecting to ncm
     * @param {String} data.settings.connectionCheckerUrl - Api point for checking activity of platform services.
     */
    initializeNcmService: function (data) {
      var i;
      connToken = data.token || '';
      username = data.username || '';
      userLang = data.language;
      deliveryPlatform = data.deliveryPlatform;
      channels = angular.copy(data.channels);
      deviceUuid = data.deviceUuid;

      for (i in data.channels) {
        channels[i] = {};
        channels[i].id = data.channels[i].cpvUuid;
        channels[i].subscribeConfig = data.channels[i].subscribeConfig;
        channels[i].lastTimeAlive = null;
        channels[i].aliveTimeout = null;
      }

      // merge new settings into default one
      angular.merge(settings, data.settings);
    },

    /**
     * Initiate socket connection and
     * register main connection events
     *
     * @param {String} id - Id of socket connection kept in nabSocket
     * @param url
     * @param ioParams
     * @returns {Function|promise}
     */
    startConnection: function (id, url, ioParams) {
      var defer = $q.defer();

      conn = nabSocket.createConnection(
        {
          token: connToken,
          username: username
        },
        id,
        url,
        ioParams
      );

      conn.on('connect').then(
        angular.noop,
        angular.noop,
        function () {
          onConnectionEstablished();
          defer.resolve();
        }
      );

      conn.on('disconnect').then(
        angular.noop,
        angular.noop,
        onDisconnect
      );

      conn.on('reconnecting').then(
        angular.noop,
        angular.noop,
        function () {
          defer.resolve();
          $log.warn('[SevenClientCore] SevenNcm:Reconnecting', {
            code: 'S_SCM_RECONNECTING'
          });
        }
      );

      conn.on('reconnect').then(
        angular.noop,
        angular.noop,
        function () {
          $log.info('[SevenClientCore] SevenNcm:Reconnect', {
            code: 'S_SCM_RECONNECT'
          });
        }
      );

      conn.on(pongName).then(
        angular.noop,
        angular.noop,
        function () {
          if (!settings.useSystemConnCheck) {
            refreshLastTimeAlive();
          }
        }
      );

      return defer.promise;
    },

    addAdditionalChannels: function (cannelsList) {
      cannelsList.forEach(function (channel) {
        additionalChannels[channel.name] = channel.id;
      });
    },

    addSubChannels: function (subChannels, channel) {
      var game;
      if (channel) {
        conn.emit(
          'addSubChannels',
          {
            channel: channels[channel].id,
            subChannels: subChannels
          }
        );
        return;
      }

      for (game in channels) {
        conn.emit(
          'addSubChannels',
          {
            channel: channels[game].id,
            subChannels: subChannels
          }
        );
      }
    },

    removeSubChannels: function (subChannels, channel) {
      var game;
      if (channel) {
        conn.emit('removeSubChannels', { channel: channels[channel].id, subChannels: subChannels });
        return;
      }

      for (game in channels) {
        conn.emit('removeSubChannels', { channel: channels[game].id, subChannels: subChannels });
      }
    },

    unSubscribeChannel: unSubscribeChannel,

    subscribeGameChannel: subscribeGameChannel,

    sendMessage: sendMessage,

    checkConnection: checkConnectionAlive,

    /**
     * Return list of subscriptions
     *
     * @returns {Function|promise}
     */
    getSubscriptionInfo: function () {
      var defer = $q.defer();

      conn.emit('getSubscriptionInfo').then(function (response) {
        defer.resolve(response);
      });

      return defer.promise;
    },

    /**
     * Start socket connection to SCM services
     *
     * @param {Object} params
     * @param {Object} [params.channels]
     * @returns {*|Function|promise}
     * @private
     */
    startScmConnection: function (params) {
      // ncm initialise
      this.initializeNcmService({
        token: params.ncm.token,
        username: params.ncm.username,
        language: params.language,
        deliveryPlatform: params.deliveryPlatform,
        channels: params.channels, // games channels
        deviceUuid: params.deviceUuid,
        settings: params.ncm
      });

      this.addAdditionalChannels(params.ncm.additionalChannels);

      return this.startConnection(params.deliveryPlatform);
    }
  };
}

export default SevenNcmSvc;
