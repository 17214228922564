/**
 * Handles 7Shop Notifications
 *
 * @class NotificationsService
 * @memberOf module:"7Shop.Common"
 */
/* @ngInject */
function NotificationsService($rootScope, $timeout, $translate) {
  var self = this;

  const generateNotification = (config) => {
    const {
      reject, resolve, productId, ...rest
    } = config;
    config = rest;

    return {
      uid: Math.floor(Date.now() * Math.random()),
      config,
      actions: [],
      // Used for handling click callback and not attaching  the buttons
      clickCb: null,
      closeDisabled: false,
      uiBlocking: false,
      reject,
      resolve,
      productId
    };
  };

  /**
     * @type {Notification | null}
     * @memberOf NotificationsService
     */
  this.current = null;

  /**
     *
     * @type {Array.<Notification>}
     * @memberOf NotificationsService
     */
  this.notifications = [];

  /**
     *
     * @type {boolean}
     * @memberOf NotificationsService
     */
  this.isActionExecuted = false;

  /**
     *
     * @type {function}
     * @memberOf NotificationsService
     */
  this.onSuccess = (notification, params) => {
    if (notification.resolve) {
      if (params) {
        notification.resolve(params);
      } else {
        notification.resolve();
      }
    }
  };

  /**
     *
     * @type {function}
     * @memberOf NotificationsService
     */
  this.onCatch = (notification) => {
    if (notification.reject) {
      notification.reject();
    }
  };

  /**
     * Show notification
     *
     * @memberOf NotificationsService
     *
     * @param {Object} config
     * @param {String} config.name
     * @param {String | Array} config.message
     * @param {String} [config.type=info]
     * @param {Number|{time, callback}} config.delay
     * @param {String} config.focus
     * @param {Array} [config.actions]
     * @param {Array.<Object>} config.actions
     * @param {boolean} config.actions[].autoClose
     * @param {Boolean | Object} [config.showDefaultAction]
     * @param {Boolean} [config.showActions]
     * @param {Config} [config.style]
     * @return {Notification}
     */
  this.show = function (config) {
    if (config.id) {
      this.closeNotificationWithId(config.id);
    }
    config.actions = config.actions || [];

    config.type = config.type ? config.type : 'info';

    if (!angular.isArray(config.message)) {
      const message = config.message;
      config.message = [message];
    }

    const newNotification = generateNotification(config);

    if (newNotification.config.delay && newNotification.reject) {
      newNotification.config.delay = {
        time: newNotification.config.delay,
        callback: function () {
          if (!this.isActionExecuted) {
            self.onCatch(newNotification);
          }
        }
      };
    }

    this.notifications.push(newNotification);

    if (config.showDefaultAction) {
      this._addDefaultAction(
        newNotification,
        angular.isObject(config.showDefaultAction) ? config.showDefaultAction : {}
      );
    }

    if (config.actions.length) {
      this._addActions(config.actions, newNotification);
    }

    if (config.clickCb) {
      this.clickCb = config.clickCb;
    }

    if (config.closeDisabled) {
      this.closeDisabled = config.closeDisabled;
    }

    if (config.uiBlocking) {
      this.uiBlocking = config.uiBlocking;
    }

    // if delay close notification after time
    if (angular.isDefined(config.delay) && config.delay) {
      this._setRemoveWithDelayNotification(newNotification);
    }

    // set this as current showed notification
    if (!this.current || newNotification.config.actions.length) {
      // this could be called out of angular digest
      // (no click, no http) so run it inside timeout
      $rootScope.$evalAsync(function () {
        this.current = newNotification;
        if (this.dialog) {
          this.dialog.onOpen();
        }
      }.bind(this));
    }

    return newNotification;
  };

  /**
     * Remove notification from stack
     *
     * @memberOf NotificationsService
     * @param {Notification} notificationToRemove
     */
  this.removeNotification = function (notificationToRemove) {
    if (!notificationToRemove) {
      return;
    }

    $timeout.cancel(notificationToRemove.timeout);

    if (notificationToRemove.config.delay
      && notificationToRemove.config.delay.callback) {
      notificationToRemove.config.delay.callback();
    }

    for (let i = 0; i < this.notifications.length; i += 1) {
      const notification = this.notifications[i];
      if (notificationToRemove.uid === notification.uid) {
        this.notifications.splice(i, 1);
      }
    }

    $rootScope.$evalAsync(function () {
      if (this.notifications.length) {
        // this could be called out of angular digest
        // (no click, no http) so run it inside timeout
        this.current = this.notifications[0];
        if (self.dialog) {
          self.dialog.onOpen();
        }
      } else {
        this.current = null;
      }
    }.bind(this));
  };

  /**
     * Close popup notification with specific id
     *
     * @memberOf NotificationsService
     * @param {string} notificationId
     */
  this.closeNotificationWithId = function (notificationId) {
    for (let i = 0; i < this.notifications.length; i += 1) {
      const notification = this.notifications[i];
      if (notificationId === notification.config.id) {
        this.removeNotification(notification);
      }
    }
  };

  /**
     * Add possible actions for opened notification
     *
     * @memberOf NotificationsService
     * @param actions
     * @param {Notification} notification
     * @private
     */
  this._addActions = function (actions, notification) {
    // add new actions
    actions.forEach((action) => {
      notification.actions.push(action);
    });
  };

  /**
     * Run action callbacks
     *
     * @memberOf NotificationsService
     * @param {Notification} notification
     * @param {Object} action
     * @param {Object} action.messageBack
     * @param {String} action.messageBack.name
     */
  this.runAction = function (notification, action) {
    // if clicked on Back button or other possible action buttons
    if (action) {
      if (action.id === 'defaultClose' && notification.reject) {
        this.onCatch(notification);
      } else if (notification.resolve) {
        this.onSuccess(notification, action);
      }

      if (angular.isFunction(action.callback)) {
        action.callback(action.callbackParams);
      }
    } else {
      // if clicked on X icon
      this.onCatch(notification);
    }

    this.removeNotification(notification);
  };

  /**
     * @memberOf NotificationsService
     * @param notification
     * @private
     */
  this._setRemoveWithDelayNotification = function (notification) {
    let delay;
    var config = notification.config;
    if (angular.isObject(config.delay)) {
      delay = config.delay.time;
    } else {
      delay = config.delay;
    }

    notification.timeout = $timeout(function () {
      self.removeNotification(notification);
    }, delay);
  };

  /**
     * @memberOf NotificationsService
     * @param {Notification} notification
     * @param {object} [config]
     * @param {string} config.key
     * @param {string} config.text
     * @param {Array.<number>} config.keyCode
     * @private
     */
  this._addDefaultAction = function (notification, config) {
    var defaultAction = angular.extend({
      id: 'defaultClose',
      className: 'cancel_btn',
      text: $translate.instant('actions.back'),
      info: $translate.instant('actions.back'),
      key: 1,
      keyCode: [49, 35, 97]
    }, config || {});

    defaultAction.callback = function () {
      this.removeNotification(notification);
      this.onCatch(notification);

      if (this.dialog
                && Object.prototype.hasOwnProperty.call(this.dialog, 'onDefaultClose')) {
        this.dialog.onDefaultClose();
      }
    }.bind(this);

    notification.actions.push(defaultAction);
  };
}

export default NotificationsService;
