(function () {
  angular.module('7Shop.Common').service('CommonService', CommonService);

  function CommonService($http, $cacheFactory, SevenRoutes) {
    var commonCache = $cacheFactory('commonCache');
    var url = SevenRoutes.mainUrl;
    var urls = {
      countries: url + '/shop/countries.json',
      languages: url + '/shop/locales.json',
      timezones: url + '/shop/timezones.json',
      dateFormats: url + '/common/dateformats.json',
      currencies: url + '/common/currencies.json',
      titles: url + '/common/titles.json',
      oddsTypes: url + '/common/oddstypes.json',
      oddsOptions: url + '/common/odds_options.json',
      genders: url + '/common/genders.json'
    };

    return {
      init: function () {

      },
      getTitles: function () {
        return $http.get(urls.titles, {
          cache: commonCache
        });
      },

      getCountries: function () {
        return $http.get(urls.countries, {
          cache: commonCache
        });
      },

      getTimezones: function () {
        return $http.get(urls.timezones, {
          cache: commonCache
        });
      },

      getLanguages: function () {
        return $http.get(urls.languages, {
          cache: commonCache
        });
      },

      getGenders: function () {
        return $http.get(urls.genders, {
          cache: commonCache
        });
      }
    };
  }
})();
