(function () {
  /* eslint-disable no-multi-assign */
  var ShopDeviceDataLoaders = window.ShopDeviceDataLoaders = window.ShopDeviceDataLoaders || {};
  var injector = angular.injector(['ng']);
  var $http = injector.get('$http');
  var $q = injector.get('$q');
  var narAppConfigUrl = 'http://127.0.0.1:8082';
  var narDeviceUrl = 'http://127.0.0.1:8081';

  ShopDeviceDataLoaders.sevenDeviceLoader = {

    isInstalled: function () {
      return $q.resolve();
    },

    getData: function (params) {
      var promises = {};

      // Fetch app data from runtime (N/WAR)
      promises.appConfig = $http.post(narAppConfigUrl + '/get_app_config', {
        windowTitle: params.title
      });
      promises.device = $http.get(narDeviceUrl);

      return $q.all(promises);
    }
  };
})();
