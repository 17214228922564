import angular from 'angular';
import customerCardsService from './customerCardsService';
import commonModule from '../../common';

const moduleName = angular
  .module('7Shop.CustomerCards', [commonModule, '7Shop.Core'])
  .service('customerCardsService', customerCardsService)
  .name;

export default moduleName;
