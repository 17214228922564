/* @ngInject */
function SevenMaintenanceService(
  $log,
  $interval,
  $timeout,
  $q,
  $translate,
  $transitions,
  $stateParams,
  nabMessaging,
  SevenMaintenanceApiService,
  errorParser,
  NabNotifications,
  HooksManager
) {
  const MAINTENANCE_SCHEDULE_CHECK_INTERVAL = 2 * 60 * 1000;
  let intervalCheck = null;
  let scheduledCheck = null;

  this.maintenanceMode = {
    affectedProducts: [],
    message: null,
    schedule: null,
    active: false
  };
  /**
    * Get all blocked products per tenant
    *
    * @returns {Array.<string>}
  */
  this.getAffectedProducts = () => this.maintenanceMode.affectedProducts;

  /**
    * Get current maintenance message
    *
    * @returns {{uuid: string, title: string, body: string, scope: Object}}
  */
  this.getMessage = () => this.maintenanceMode.message;

  /**
    * Get schedule of the current maintenance message
    *
    * @returns {Array.<{uuid: string, start: string, end: string}>}
  */
  this.getSchedule = () => this.maintenanceMode.schedule;

  /**
    * Check if maintenance mode is active
    *
    * @returns {Boolean}
  */
  this.isMaintenanceActive = () => this.maintenanceMode.active;

  /**
     * Localize product name based on the used language
     *
     * @param {String} productName
  */
  this.getLocalizedProductName = (productName) => $translate.instant(`games.${productName.toLowerCase()}`);

  this.getHtmlFormattedNotificationMessage = () => {
    const listItems = [];

    this.getAffectedProducts().forEach((product) => {
      const localizedProductName = this.getLocalizedProductName(product);
      const listItem = `<h5>${localizedProductName}</h5>`;
      listItems.push(listItem);
    });

    return `<h4>${this.getMessage().body}</h4><br/>
      <h5>${$translate.instant('seven_maintenance_affected_products_list_title')}:</h5>
      <ul>
        <li>${listItems.join('</li><li>')}</li>
      </ul>
    `;
  };
  /**
     * Add new affected product to array
     *
     * @param {String} newAffectedProduct
  */
  this.setAffectedProduct = (newAffectedProduct) => {
    this.maintenanceMode.affectedProducts.push(newAffectedProduct);
  };

  this.clearAffectedProducts = () => {
    this.maintenanceMode.affectedProducts.splice(0, this.maintenanceMode.affectedProducts.length);
  };

  /**
     * Enable/disable maintenance mode
     *
     * @param {Boolean} mode
  */
  this.setMaintenanceActiveState = (mode) => {
    this.maintenanceMode.active = mode;
  };

  /**
    * Store current maintenance message
    *
    * @param {{uuid: string, title: string, body: string, scope: Object}} message
  */
  this.setMessage = (message) => {
    this.maintenanceMode.message = message;
  };

  /**
    * Store schedule config for current maintenance message
    *
    * @param {Array.<{uuid: string, start: string, end: string}>} schedule
  */
  this.setSchedule = (schedule) => {
    this.maintenanceMode.schedule = schedule;
  };

  this.getDisabledProductsFromAdmin = (messageScope) => {
    const disabledProducts = Object.values(messageScope)
      .map((channel) => channel.Retail)
      .flatMap((products) => products)
      .filter(Boolean);

    return disabledProducts.length ? disabledProducts : undefined;
  };

  this.removeMaintenanceMode = () => {
    this.clearAffectedProducts();
    this.setMaintenanceActiveState(false);
    NabNotifications.closeNotificationWithId('maintenance_msg');
    $log.debug('[7Shop.SevenMaintenance] Maintenance mode removed', {
      code: 'S_MAINTENANCE_REMOVED'
    });
  };

  this.activateMaintenance = () => {
    this.clearAffectedProducts();
    const messageScope = this.getMessage().scope;
    const disabledProductsFromAdmin = this.getDisabledProductsFromAdmin(messageScope);

    disabledProductsFromAdmin.forEach((product) => {
      this.setAffectedProduct(product);
    });

    if (!this.isMaintenanceActive()) {
      $log.debug('[7Shop.SevenMaintenance] Maintenance mode activated.', {
        code: 'S_MAINTENANCE_ACTIVATED',
        affectedProducts: this.getAffectedProducts()
      });
    }

    this.setMaintenanceActiveState(true);

    if (this.getAffectedProducts().indexOf($stateParams.gameId) !== -1) {
      this.showNotification();
    }
  };

  this.isScheduleTimeValid = (scheduleItem) => {
    const currentUtcTime = moment().utc();

    return currentUtcTime >= moment(scheduleItem.start).utc() && currentUtcTime < moment(scheduleItem.end).utc();
  };

  this.handleMaintenanceSchedule = () => {
    SevenMaintenanceApiService.getSchedule()
      .then(({ data }) => {
        if (!data.total) {
          if (this.isMaintenanceActive()) {
            this.removeMaintenanceMode();
          }
          return;
        }

        this.setMessage(data.results[0].message);
        this.setSchedule(data.results[0].schedule);

        if (!this.getSchedule().length > 0) {
          this.removeMaintenanceMode();
          return;
        }

        this.getSchedule().forEach((scheduleItem) => {
          const valid = this.isScheduleTimeValid(scheduleItem);

          if (valid) {
            const currentUtcTime = moment().utc();

            this.activateMaintenance();
            this.scheduleEndTimeCheck(moment(scheduleItem.end).utc() - currentUtcTime);
          }
        });
      }).catch((err) => {
        $log.error('[7Shop.SevenMaintenance] Failed to fetch maintenance schedule', {
          ...errorParser.parseUpstream(err),
          code: 'S_MAINTENANCE_API_SCHEDULE_FETCH_FAILED'
        });
      });
  };

  /**
    * Validate if the product is affected by maintenance mode and blocked in Admin
    *
    * @param params: Object
    * @param params.productId: String
    *
    * @returns { Promise }
  */
  this.validateBlockedProducts = (params) => {
    const deffered = $q.defer();
    const blockedProduct = this.getAffectedProducts().findIndex((product) => product === params.productId) !== -1;

    if (blockedProduct) {
      deffered.reject({
        message: $translate.instant('seven_maintenance_blocked_product_error'),
        code: 'S_SEVEN_MAINTENANCE_PRODUCT_BLOCKED_BEFORE_PAYIN'
      });
    } else {
      deffered.resolve(params);
    }

    return deffered.promise;
  };

  this.startIntervalCheck = () => {
    $interval.cancel(intervalCheck);
    intervalCheck = $interval(this.handleMaintenanceSchedule, MAINTENANCE_SCHEDULE_CHECK_INTERVAL);
  };

  this.scheduleEndTimeCheck = (timeToEnd) => {
    $timeout.cancel(scheduledCheck);
    scheduledCheck = $timeout(this.handleMaintenanceSchedule, timeToEnd);
  };

  this.showNotification = () => {
    NabNotifications.show({
      message: this.getHtmlFormattedNotificationMessage(),
      id: 'maintenance_msg',
      type: 'warning',
      hideCloseButton: true
    });
  };

  this.registerSocketListener = () => {
    nabMessaging.subscribe('Maintenance.Update', this.handleMaintenanceSchedule);
  };

  this.registerActiveGameListener = () => {
    $transitions.onSuccess({}, ($transition) => {
      if (
        $transition.$to().name.startsWith('app.home.games')
        && this.getAffectedProducts().indexOf($transition.params().gameId) !== -1
      ) {
        this.showNotification();
        return;
      }

      NabNotifications.closeNotificationWithId('maintenance_msg');
    });
  };

  this.registerHooks = () => {
    HooksManager.getHook('BeforeTicketPayin').tapPromise({
      name: 'BeforeTicketPayin.BlockedProductsValidation',
      fn: this.validateBlockedProducts
    });
  };

  this.init = () => {
    this.handleMaintenanceSchedule();
    this.registerSocketListener();
    this.startIntervalCheck();
    this.registerActiveGameListener();
    this.registerHooks();
  };
}

export default SevenMaintenanceService;
