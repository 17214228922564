/**
* @class CloudflareService
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function CloudflareService(GravitySettings, NabENV) {
  /**
   * Replaces requested url to cloudflare route if exists in cloudflareList.
   *
   * @memberOf module:"7Shop.Core".CloudflareService
   * @param {string} url
   * @returns {string}
   */
  const createCloudflareUrl = function (url) {
    const cloudflareList = NabENV.cloudflare.searchReplaceList || [];
    let cloudflareUrl = url;
    cloudflareList.forEach((list) => {
      const shouldReplaceUrl = list.search.includes(url);
      if (shouldReplaceUrl) {
        cloudflareUrl = url.replace(list.search, list.replaceWith);
      }
    });
    return cloudflareUrl;
  };

  /**
   * Generates cloudflare url based on CMS cloudflareEnabledv2 config value.
   *
   * @memberOf module:"7Shop.Core".CloudflareService
   * @param {string} url
   * @returns {string}
   */
  this.generateCloudflareUrl = function (url) {
    const isCloudflareEnabled = GravitySettings.getModuleDataKeyValue('config', 'cloudflareEnabledv2');
    let parsedUrl;
    let cloudflareUrl;

    if (!isCloudflareEnabled) return url;

    try {
      parsedUrl = new URL(url);
    } catch (error) {
      return url;
    }

    cloudflareUrl = parsedUrl.origin;

    if (isCloudflareEnabled) {
      cloudflareUrl = createCloudflareUrl(cloudflareUrl);
    }

    return cloudflareUrl + parsedUrl.pathname + parsedUrl.search;
  };
}

export default CloudflareService;
