/* eslint-disable angular/timeout-service */
/* @ngInject */
function logService(
  $log,
  $interval,
  NabENV,
  HooksManager,
  ShopEnvironment,
  errorParser
) {
  const basicAuth = (auth) => 'Basic ' + btoa(`${auth.username}:${auth.password}`);
  const deepCopyObject = (source) => {
    if (typeof structuredClone === 'function') {
      try {
        return structuredClone(source);
      } catch (err) {
        // cannot use $log here, it will throw Maximum call stack size exceeded error
        // eslint-disable-next-line no-console
        console.warn('[7Shop.Logger] Log copy failed. Fallback to JSON api', {
          code: 'S_LOGGER_COPY_STRUCT_ERR',
          ...errorParser.parseUpstream(err)
        });
        return JSON.parse(JSON.stringify(source));
      }
    }

    return JSON.parse(JSON.stringify(source));
  };
  const LS_KEY_PREFIX = 'shop.logs-backup-';
  const REQUEST_TIMEOUT = 10_000;
  const BACKUP_ITEMS_LIMIT = 15;

  this.logs = [];

  this.clearLogsQueue = () => {
    this.logs = [];
  };

  this.sendLogsToGraylog = (logs) => new Promise((resolve, reject) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

    fetch(`${NabENV.graylog.url}/bulk_logs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth(NabENV.graylog.auth)
      },
      body: JSON.stringify(logs),
      signal: controller.signal
    }).then((response) => {
      clearTimeout(id);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      resolve();
    }).catch((err) => {
      clearTimeout(id);
      reject(err);
    });
  });

  this.sendLogsAndClearLogsQueue = () => {
    const logsToSend = deepCopyObject(this.logs);
    this.clearLogsQueue();
    return this.sendLogsToGraylog(logsToSend).catch((err) => {
      $log.error('[7Shop.Logger] Send logs on send and clear failed', {
        code: 'S_LOGGER_SEND_AND_CLEAR_ERR',
        ...errorParser.parseUpstream(err)
      });
      // failed? we need to retry
      const lsKey = this.saveLocallyFailedSend(logsToSend);
      this.retrySendingLogsAfterFail(logsToSend, lsKey);
      return Promise.reject(err);
    });
  };
  /**
     * Send logs to graylog periodically
     */
  this.startIntervalSendLogs = function () {
    $interval(() => {
      if (this.logs.length > 0) {
        this.sendLogsAndClearLogsQueue()
          .catch(() => {
            $log.error('[7Shop.Logger] Send logs on interval failed', {
              code: 'S_LOGGER_SEND_AND_CLEAR_INTERVAL_ERR'
            });
          });
      }
      this.retryFailedToSendLogsFromLS();
    }, NabENV.graylog.intervalLimit, 0, false);
  };

  this.saveLocallyFailedSend = (logs, persistKey) => {
    const key = persistKey || `${LS_KEY_PREFIX}${Date.now()}`;

    // Remove the oldest log if we exceed the limit
    const lsKeys = Object.keys(localStorage).filter((lsKey) => lsKey.startsWith(LS_KEY_PREFIX)).sort();
    if (lsKeys.length >= BACKUP_ITEMS_LIMIT) {
      const oldestKey = lsKeys[0]; // Oldest key (first in the sorted list)
      localStorage.removeItem(oldestKey); // Remove oldest log
    }

    localStorage.setItem(key, JSON.stringify(logs));
    return key;
  };

  this.retrySendingLogsAfterFail = (logs, lsKey) => {
    const LOGS_RESEND_TIMEOUT = 1000 * 60;
    // try to send them once again in 1 minute
    setTimeout(() => {
      // if sent in the mean time by retryFailedToSendLogsFromLS, do not proceed
      if (!localStorage.getItem(lsKey)) {
        return;
      }

      localStorage.removeItem(lsKey);

      this.sendLogsToGraylog(logs).catch((err) => {
        // failed? we need to retry
        this.saveLocallyFailedSend(logs, lsKey);
        $log.error('[7Shop.Logger] Failed to send logs on retry', {
          code: 'S_LOGGER_RETRY_ERR',
          ...errorParser.parseUpstream(err)
        });
      });
    }, LOGS_RESEND_TIMEOUT);
  };

  this.retryFailedToSendLogsFromLS = () => {
    // eslint-disable-next-line angular/window-service
    const lsKeys = Object.keys(localStorage);
    const foundFailedKeys = lsKeys.filter((key) => key.startsWith(LS_KEY_PREFIX)).sort();

    if (foundFailedKeys.length) {
      // send only one batch, the oldest one
      const keyName = foundFailedKeys[foundFailedKeys.length - 1];
      const logs = JSON.parse(localStorage.getItem(keyName));
      localStorage.removeItem(keyName);

      this.sendLogsToGraylog(logs).catch((err) => {
        $log.error('[7Shop.Logger] Send logs from LS failed', {
          code: 'S_LOGGER_RETRY_FROM_LS_ERR',
          ...errorParser.parseUpstream(err)
        });
        // failed? we need to retry
        this.saveLocallyFailedSend(logs, keyName);
      });
    }
  };

  this.isLogStreamEnabled = () => !ShopEnvironment.isRunOnLocalhost()
        && !ShopEnvironment.isRunBySevenCli();

  HooksManager.getHook('BeforeLogout').tapPromise({
    name: 'BeforeLogout.SendLogs',
    fn: () => {
      if (this.logs.length) {
        return this.sendLogsAndClearLogsQueue()
          .catch(() => Promise.resolve());
      }

      return Promise.resolve();
    }
  });
}
export default logService;
