import sGravityControlHtml from './sGravityControl.html';

/**
* @class sGravityControlController
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function sGravityControlController($filter, KeyMapper, ScanSvc) {
  var ctrl = this;
  ctrl.dropdownMethods = [];

  ctrl.$onInit = function () {
    generateMethods();
  };

  /**
   * @param {string} fieldFormName
   * @param {Event} e
   */
  ctrl.handleInputScan = (fieldFormName, e) => {
    if (ScanSvc.shouldPropagateScanKey(e.key)) {
      return;
    }

    e.stopPropagation();
    const code = ctrl.formViewModel[fieldFormName];
    if (!code || e.key !== KeyMapper.enter.text) {
      return;
    }

    ctrl.onScanFinished({ code });
    ctrl.formViewModel[fieldFormName] = '';
    e.preventDefault();
  };

  function generateMethods() {
    var keyNames;
    if (ctrl.dropdownData) {
      keyNames = Object.keys(ctrl.dropdownData);
      if (keyNames) {
        keyNames.forEach((element) => {
          ctrl['dropdownSelect' + element] = function (data) {
            ctrl.dropdownData[element].selectedValue = $filter('filter')(
              ctrl.dropdownData[element].selectData,
              { value: data },
              true
            )[0];
          };
        });
      }
    }
  }
}

export default {
  templateUrl: sGravityControlHtml,
  controller: sGravityControlController,
  bindings: {
    field: '<',
    formViewModel: '<',
    dropdownData: '<',
    form: '<',
    touch: '<',
    onScanFinished: '&'
  }
};
