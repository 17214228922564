// eslint-disable-next-line import/no-cycle
import coreModule from '../core';
import googleAnalyticsModule from '../integrations/google_analytics';

import sGamesHelp from './games_help/gamesHelpComponent';
import sGamesHelpFooter from './games_help/helpFooterComponent';
import gameService from './gameService';

/**
 * @module "7Shop.Games"
 */
const GamesModule = angular.module('7Shop.Games', [coreModule, googleAnalyticsModule])
  .component('sGamesHelp', sGamesHelp)
  .component('sGamesHelpFooter', sGamesHelpFooter)
  .service('GameService', gameService)
  .name;

export default GamesModule;
