import { findIndex } from 'underscore';
import dropdownTemplateUrl from './dropdownTemplate.html';

/**
 * dropdownSelectedData - selected value
 * dropdownData - data which we are filtering
 * dropdownId - id of dropdown
 * dropdownRegexType - by default it compares from the beginning of the string
 * - possible value is look-ahead - seacrh from anywhere
 *
 */
(function () {
  angular.module('7Shop.Common')
    .directive('sDropdownInput', sDropdownInput);

  function sDropdownInput(
    $templateRequest,
    $compile,
    $rootScope,
    $document
  ) {
    return {
      scope: {
        sDropdownSelectedData: '=',
        sDropdownData: '=',
        sDropdownSelect: '=',
        sDropdownId: '@',
        sDropdownRegexType: '@',
        sDropdownPosition: '@',
        sDropdownDisable: '=?'
      },
      link: function (scope, elem) {
        const position = scope.sDropdownPosition ? scope.sDropdownPosition : 'down';
        let scrollStep = 34; // Default hardcoded value
        let dropdownVisible = false;
        let enteredValue = '';
        let focusTriggered = false;

        function setPosition() {
          const listContainer = elem.next();
          listContainer.css({
            top: position === 'down' ? elem.outerHeight() + 3 : -listContainer.outerHeight() - 3
          });
        }

        function initScrollStep() {
          // if there is no data .dropdown-option
          // will be hidden
          if (!scope.sDropdownData.length) {
            return;
          }

          // we need to get height of single row,
          // each scroll action will move top/bottom for this value
          const element = elem.parent().find('.dropdown-option');
          if (element && element[0].clientHeight) {
            scrollStep = element[0].clientHeight;
          }
        }

        $templateRequest(dropdownTemplateUrl).then(function (content) {
          const html = angular.element(content);
          const template = angular.element($compile(html)(scope));
          elem.after(template);
        });

        scope.selectedIndex = 0;

        /**
         * Toggle dropdown
         * @param {KeyboardEvent} e
         */
        elem.on('focus click', function (e) {
          e.stopPropagation();

          if (e.type === 'focus') {
            focusTriggered = true;
            scope.openDropdown();
            return;
          }

          // after focus don't call click handlers
          if (focusTriggered) {
            focusTriggered = false;
            return;
          }

          if (dropdownVisible) {
            scope.closeDropdown();
          } else {
            scope.openDropdown();
          }
        });

        elem.on('keydown', function (e) {
          let keyInput;

          if (!dropdownVisible) {
            return false;
          }

          if (e.which === 40) {
            /**
             * Key down
             */
            if (scope.sDropdownData[scope.selectedIndex + 1]) {
              scope.selectedIndex += 1;
              scrollDown();
            }
            $rootScope.$evalAsync();
          } else if (e.which === 38) {
            /**
             * Key up
             */
            if (scope.sDropdownData[scope.selectedIndex - 1]) {
              scope.selectedIndex -= 1;
              scrollUp();
            }
            $rootScope.$evalAsync();
          } else if (e.which === 13) {
            /**
             * On enter
             */
            scope.selectData();
            scope.closeDropdown();
            // Trigger dygest manually
            $rootScope.$evalAsync();
          } else if (e.which === 27 || e.which === 9) {
            /**
             * Escape or tab
             */
            scope.closeDropdown();
          } else if (e.which === 111) {
            e.preventDefault();
            /**
             * Divider character
             */
            enteredValue += '/';
          } else if (e.which === 46) {
            e.preventDefault();
            /**
             * Backspace
             */
            enteredValue = enteredValue.slice(0, -1);
            filterInput();
          } else if (e.which === 8) {
            e.preventDefault();
            /**
             * Delete
             */
            enteredValue = '';
          } else {
            e.preventDefault();
            keyInput = String.fromCharCode(e.which);
            /**
             * If value is alphabet character
             */
            if (/[a-zA-Z]/mig.test(keyInput)) {
              enteredValue += keyInput;
              filterInput();
            }
          }

          return true;
        });

        scope.openDropdown = function () {
          dropdownVisible = true;
          $($document[0].getElementById(scope.sDropdownId))
            .removeClass('hidden')
            .addClass('visible');

          $document.on('click.dropdown', function (e) {
            if (e.target.className.indexOf(scope.sDropdownId) === -1) {
              scope.closeDropdown();
            }
          });
          initScrollStep();
          setSelectedIndex(scope.sDropdownSelectedData);
          setScrollPosition();
          setPosition();
          $rootScope.$evalAsync();
        };

        scope.selectData = function (index) {
          // Because bloody 0 is false
          const data = typeof index !== 'undefined' ? scope.sDropdownData[index] : scope.sDropdownData[scope.selectedIndex];
          scope.sDropdownSelect(data.value, data);
        };

        scope.closeDropdown = function () {
          dropdownVisible = false;
          $document.off('click.dropdown');
          $($document[0].getElementById(scope.sDropdownId))
            .removeClass('visible').addClass('hidden');
        };

        scope.$watch('sDropdownDisable', function () {
          elem.prop('disabled', scope.sDropdownDisable);
        });

        /**
         * Run filter as long as there are matching results, if there isn't
         * run search again with last enered letter
         */

        function filterInput() {
          const regEx = scope.sDropdownRegexType === 'look-ahead' ? new RegExp('.+(?=' + enteredValue + ')', 'mig')
            : new RegExp('^' + enteredValue, 'mig');

          const filteredValues = scope.sDropdownData.filter(function (value) {
            if (regEx.test(value.name)) {
              return value;
            }

            return false;
          });

          if (filteredValues.length) {
            setSelectedIndex(filteredValues[0].value);
            setScrollPosition();
          } else {
            enteredValue = enteredValue.slice(-1);
          }
        }

        function setSelectedIndex(value) {
          if (!value) {
            scope.selectedIndex = 0;
          } else {
            scope.selectedIndex = findIndex(scope.sDropdownData, function (element) {
              return element.value === value;
            });
          }
        }

        function setScrollPosition() {
          $($document[0].getElementById(scope.sDropdownId))
            .scrollTop(scope.selectedIndex * scrollStep);
        }

        function scrollDown() {
          const currentPosition = $($document[0].getElementById(scope.sDropdownId))
            .scrollTop();
          $($document[0].getElementById(scope.sDropdownId))
            .scrollTop(currentPosition + scrollStep);
        }

        function scrollUp() {
          const currentPosition = $($document[0].getElementById(scope.sDropdownId))
            .scrollTop();
          $($document[0].getElementById(scope.sDropdownId))
            .scrollTop(currentPosition - scrollStep);
        }
      }
    };
  }
}());
