(function () {
  angular.module('7Shop.Bootstrap')
    .factory('shopLanugageLoader', shopLanugageLoader);

  function shopLanugageLoader($q, ShopBootstrap) {
    return function () {
      var deferred = $q.defer();
      var bootstrapData = ShopBootstrap.getBootstrapData();
      if (bootstrapData && bootstrapData.translations) {
        deferred.resolve(bootstrapData.translations);
      } else {
        deferred.resolve({});
      }
      return deferred.promise;
    };
  }
})();
