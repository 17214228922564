/* @ngInject */
function SevenMaintenanceApiService(
  $http,
  SevenRoutes,
  companyService
) {
  this.getSchedule = function () {
    const params = {
      company: companyService.data.uuid,
      channel: 'Retail'
    };

    return $http({
      method: 'GET',
      url: `${SevenRoutes.mainUrl}/maintenance/api/v1/schedule`,
      params
    });
  };
}

export default SevenMaintenanceApiService;
