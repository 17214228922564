(function () {
  angular.module('7Shop.Core')
    .provider('GravityApplication', GravityApplication);

  /**
   * @memberOf module:"7Shop.Core"
   * @class GravityApplication
   */
  function GravityApplication() {
    var self = this;
    var getApp = ($http, baseUrl, companyUuid) => $http.get(baseUrl + '/apps/' + companyUuid);

    this._data = {};

    /**
     * @memberOf module:"7Shop.Core".GravityApplication
     */
    this.setAppName = function (appName) {
      this._data.appName = appName;
    };

    /**
     * @memberOf module:"7Shop.Core".GravityApplication
     */
    this.getAppName = function () {
      return this._data.appName;
    };

    /**
     *
     */
    this.$get = function (
      $http,
      $log,
      NabENV,
      errorParser
    ) {
      /**
       * Gets shop gravity app base url.
       *
       * @memberOf module:"7Shop.Core".GravityApplication
       * @returns {string}
       */
      const getAppBaseUrl = function () {
        return NabENV.api.gravity.shop.replace(
          NabENV.cloudflare.endpoints.app.search,
          NabENV.cloudflare.endpoints.app.replace
        );
      };

      /**
       * Gets gravity app data.
       *
       * @memberOf module:"7Shop.Core".GravityApplication
       * @param {string} companyUuid
       * @returns {GravityApplication}
       */
      self.fetchGravityAppName = function (companyUuid) {
        const baseUrl = getAppBaseUrl();

        return getApp($http, baseUrl, companyUuid)
          .then((response) => response)
          .catch((err) => {
            $log.warn('[7Shop.Core] Failed fetching gravity company from CF', {
              ...errorParser.parseUpstream(err),
              code: 'S_FETCH_GRAVITY_APP_CF_ERR'
            });
            return getApp($http, NabENV.api.gravity.shop, companyUuid)
              .then((res) => res)
              .catch((error) => {
                $log.warn('[7Shop.Core] Failed fetching gravity company from CF', {
                  ...errorParser.parseUpstream(error),
                  code: 'S_FETCH_GRAVITY_APP_ERR'
                });
                return false;
              });
          });
      };

      return self;
    };
  }
})();
