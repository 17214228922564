import chipTemplate from './chip.html';
import './_chip.scss';

/**
 * @ngInject
 */
function ChipController() {
  /**
   * @param {Event} e
   */
  this.remove = (e) => {
    e.preventDefault();
    this.onRemove();
  };
}

export default {
  templateUrl: chipTemplate,
  controller: ChipController,
  controllerAs: 'ctrl',
  bindings: {
    text: '<',
    type: '<?',
    showRemoveAction: '<?',
    onRemove: '&'
  }
};
