import templateUrl from './gamesHelp.html';

/* @ngInject */
function sGamesHelpCtrl(
  $rootScope,
  SevenGamesSvc,
  Shortcuts,
  GameService
) {
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.shortcutsService = Shortcuts;
  };

  ctrl.filterShortcuts = function (value) {
    return value.active === true && value.category === ctrl.category;
  };

  /**
   *
   * @param {Object} event
   * @param {S_Shortcut} shortcut
   */
  ctrl.onShortcutClick = function (event, shortcut) {
    const game = SevenGamesSvc.getActiveGame();

    // emit
    $rootScope.$emit(
      '7S:Widget.Event',
      angular.extend({}, shortcut, {
        productId: game.id,
        event: 'click',
        originalEvent: {
          type: 'click',
          target: {}
        }
      })
    );
  };

  ctrl.closeHelp = function () {
    GameService.toggleGamesHelp();
  };

  ctrl.toggleList = function () {
    ctrl.onToggleList();
  };
}

export default {
  templateUrl: templateUrl,
  bindings: {
    onToggleList: '&',
    category: '<'
  },
  controller: sGamesHelpCtrl
};
