(function () {
  angular.module('7Shop.Core')
    .factory('metadataService', metadataService);

  function metadataService(
    LocalData,
    LoginData,
    SevenTicketConfig
  ) {
    function Metadata() {
      this.ticketMeta = {
        cpvUuid: '',
        deliveryPlatform: 'Retail',
        paymentMethod: 'Cash',
        product: '',
        requestUuid: '',
        uuid: '',
        sources: [
          {
            type: 'betshop',
            uuid: LocalData.betshop.uuid
          },
          {
            type: 'operator',
            uuid: LoginData.user.uuid
          },
          {
            type: 'productInstance',
            uuid: ''
          },
          {
            type: 'device',
            uuid: LocalData.device.uuid
          }
        ]
      };

      this.ticketRoutes = {
        add: SevenTicketConfig.options.url + 'tickets.json',
        check: SevenTicketConfig.options.url + 'tickets/check/',
        cancel: SevenTicketConfig.options.url + 'tickets/cancel.json',
        payout: SevenTicketConfig.options.url + 'tickets/payout.json',
        reqUuidUrl: {
          baseUrl: SevenTicketConfig.options.url + 'tickets/request/',
          urlSuffix: '/check.json'
        }
      };
    }

    return Metadata;
  }
})();
