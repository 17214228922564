import angular from 'angular';
// eslint-disable-next-line import/no-cycle
import coreModule from '../../core';
import gameModule from '../../games';

import GravityGatewayIntegrator from './gravityGatewayIntegratorService';
import GravityGatewayTickets from './gravityGatewayTicketsService';
import eventDecorator from './rootScopeGravityDecorator';

const moduleName = angular.module('7Shop.Integrator', [coreModule, gameModule])
  .decorator('$rootScope', eventDecorator)
  .service('GravityGatewayIntegrator', GravityGatewayIntegrator)
  .service('GravityGatewayTickets', GravityGatewayTickets)
  .name;

export default moduleName;
