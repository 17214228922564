import angular from 'angular';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import coreModule from '../../core';
import runBlock from './runBlock';
import NarApiService from './narApiService';
import NarService from './narService';

/**
 * @module "7Shop.Logger"
 */
const narModule = angular.module('7Shop.Nar', [
  coreModule,
  'SevenClient.Config'
])
  .service('NarApiService', NarApiService)
  .service('NarService', NarService)
  .run(runBlock)
  .name;

export default narModule;
