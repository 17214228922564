/* @ngInject */
function belgiumComplianceInterceptor(NabENV) {
  /**
   * @param {string} url
   * @returns {boolean}
   */
  const isValidUrl = (url) => url.includes(NabENV.api.belgiumCompliance.url);

  /**
   * @param {Object} request
   */
  const addAuthToken = (request) => {
    if (sessionStorage.shopToken) {
      request.headers.Authorization = `Bearer ${sessionStorage.shopToken}`;
    }
  };

  return {
    request(requestConfig) {
      if (!isValidUrl(requestConfig.url)) {
        return requestConfig;
      }

      addAuthToken(requestConfig);
      return requestConfig;
    }
  };
}

export default belgiumComplianceInterceptor;
