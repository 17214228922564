import PubSub from 'pubsub-js';

/* @ngInject */
function NarService(
  $log,
  $translate,
  NarApiService,
  errorParser,
  NabNotifications
) {
  this.registerListeners = () => {
    PubSub.subscribe('7S:KeyboardEvent.poweroff', () => {
      NarApiService.devicePower('poweroff')
        .catch((err) => {
          $log.error('[7Shop.Nar] Nar returned error on poweroff keydown action', {
            code: 'S_NAR_POWER_OFF_SHORTCUT_ERR',
            ...errorParser.parseUpstream(err)
          });
          NabNotifications.show({
            message: $translate.instant('notifications.default_error_message'),
            type: 'warning',
            delay: 3000
          });
        });
    });
  };
}
export default NarService;
