/* @ngInject */
function timingInterceptor($q) {
  /**
   *
   * @param {Number} start
   * @param {Number} end
   * @returns
   */
  const messureTime = (start, end) => end - start;
  const addStartTime = (context) => {
    context.requestTimestamp = new Date().getTime();
  };
  const addDuration = (context) => {
    context.config.duration = messureTime(context.config.requestTimestamp, new Date().getTime());
  };

  return {
    request(config) {
      addStartTime(config);
      return config;
    },
    requestError(rejection) {
      addStartTime(rejection);
      return $q.reject(rejection);
    },
    response(response) {
      addDuration(response);
      return response;
    },
    responseError(rejection) {
      addDuration(rejection);
      return $q.reject(rejection);
    }
  };
}

export default timingInterceptor;
