/**
   *
   * @constructor helpersService
   */
/* @ngInject */
function helpersService(
  SevenTicket
) {
  const isNumberInRange = (number, rLeft, rRight) => number >= rLeft && number <= rRight;

  /**
   * @param {String} code
   * @returns {String}
   */
  const getTicketIdByCode = (code) => {
    const ticketPinIndex = SevenTicket.hasTicketPin(code);
    if (ticketPinIndex) {
      code = SevenTicket.stripTicketPin(code, ticketPinIndex);
    }

    return code.toUpperCase().replace(/ /g, '');
  };

  return {
    isNumberInRange,
    getTicketIdByCode
  };
}

export default helpersService;
