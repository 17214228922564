import { has, each } from 'underscore';
import PubSub from 'pubsub-js';
import templateUrlTouch from './touch/overlay.html';
import templateUrl from './overlay.html';
import templateOverlayUrl from './overlay_menu/overlay_menu.html';
import templateOverlayTouchUrl from './touch/overlay_menu/overlay_menu.html';
import templateOverlayHelpUrl from './help_overlay/help.html';

(function () {
  angular.module('7Shop.Core')
    .directive('sOverlay', overlay);

  function overlay(
    $http,
    $window,
    $compile,
    $templateCache,
    $state,
    $rootScope,
    $log,
    SevenClientCoreSvc,
    switchView,
    SevenGamesSvc,
    OverlaySvc,
    nabMessaging,
    focusNext,
    Shortcuts,
    modalSvc,
    Touch,
    GravityMenus,
    Modules,
    googleAnalyticsService,
  ) {
    function getTemplate(url) {
      return $http.get(url, { cache: $templateCache });
    }

    return {
      scope: {
        api: '='
      },
      templateUrl: Touch.isTouch() ? templateUrlTouch : templateUrl,
      link: function (scope, el, attr, ctrl) {
        // available templates
        var VIEWS = {
          menu: Touch.isTouch() ? templateOverlayTouchUrl
            : templateOverlayUrl,
          help: templateOverlayHelpUrl
        };
        var marginSize = {
          height: $window.innerWidth < 1024 ? 120 + 15 : 120
        };

        angular.element($window).on('resize', function () {
          // find out item size
          var itemSize = ctrl.getItemSize(getContainerSize());

          // set root font size of overlay
          el.find('.s-menu').css({
            'font-size': ctrl.getRootFontSize(itemSize) + 'rem'
          });

          setItemSize(itemSize.width, itemSize.height);
        });

        function getContainerSize() {
          var size = {};
          var wrapperEL = $('.overlay');
          var holderEl = wrapperEL.find('.s-menu');

          wrapperEL.attr('style', 'visibility:hidden; display: block !important');
          size.width = holderEl.width();
          size.height = $window.innerHeight - marginSize.height;
          wrapperEL.attr('style', '');

          return size;
        }

        function setItemSize(width, height) {
          scope.itemSize.width = width;
          scope.itemSize.height = height;
        }

        /**
         * Show overlay
         * @param {String} view
         */
        function showView(view) {
          var url;
          var itemSize;
          var shortcuts;

          if (!has(VIEWS, view)) return;

          url = VIEWS[view];

          getTemplate(url).then(function (response) {
            var game = SevenGamesSvc.getActiveGame();
            scope.productShortcuts = [];

            if (game && game.info.local.shortcuts) {
              shortcuts = game.info.local.shortcuts;
              each(shortcuts, function (value) {
                scope.productShortcuts.push(value);
              });
            }

            el.find('.include_overlay').empty().append($compile(response.data)(scope));

            // find out item size
            itemSize = ctrl.getItemSize(getContainerSize());

            // set root font size of overlay
            el.find('.s-menu').css({
              'font-size': ctrl.getRootFontSize(itemSize) + 'rem'
            });

            $rootScope.$evalAsync(function () {
              setItemSize(itemSize.width, itemSize.height);

              scope.show = !scope.show;

              if (scope.show) {
                // focus overlay
                focusNext('1001');
              }
            });
          });
        }

        scope.open = function (view) {
          showView(view);
        };

        const helpKeyListner = PubSub.subscribe('7S:KeyboardEvent.help_overlay', () => {
          googleAnalyticsService.trackEvent('Help', {
            event: 'Open Modal'
          });
          scope.open('help');
        });

        const toggleMenuListner = PubSub.subscribe(
          '7S:KeyboardEvent.toggle_main_menu',
          () => {
            if (!modalSvc.isModalActive()) {
              if (OverlaySvc.isOpen()) {
                OverlaySvc.close();
              } else {
                OverlaySvc.open('menu');
              }
            }
          }
        );

        scope.$on('$destroy', () => {
          PubSub.unsubscribe(helpKeyListner);
          PubSub.unsubscribe(toggleMenuListner);
        });

        scope.api.ready(scope);
      },

      controller: function ($scope, SevenMaintenanceService) {
        var sqrtRoot;
        var columnNumb;
        var rowNumb;
        var overlayMenuPriorities;
        var boxLength = {};
        var firstLoad = true;

        function arrangeOverlay() {
          var modules = SevenClientCoreSvc.getAllModules();
          // Filter modules and set priority
          var tabs = modules.filter(function (module) {
            if (overlayMenuPriorities[module.id]) {
              module.overlayPriority = overlayMenuPriorities[module.id];
              return true;
            }
            return false;
          });

          // Sort tabs by priority
          tabs.sort(function (a, b) {
            return b.overlayPriority - a.overlayPriority;
          });

          $scope.modules = tabs;
        }

        $scope.itemSize = {
          width: 0,
          height: 0
        };

        // Get menuObject so modules can be filtered and sorted
        overlayMenuPriorities = GravityMenus.getMenuAsObjectOfPriorities('megaMenu');

        arrangeOverlay();

        $scope.systemShortcuts = [].concat(
          Shortcuts.getShortcutByCategory('shop'),
          Shortcuts.getShortcutByCategory('system')
        );

        sqrtRoot = Math.sqrt($scope.modules.length);
        columnNumb = Math.ceil(sqrtRoot);
        rowNumb = Math.round(sqrtRoot);

        boxLength.column = columnNumb;
        boxLength.row = rowNumb;

        nabMessaging.subscribe('app:configFetchingSuccess', function () {
          arrangeOverlay();
        });

        /**
         * Select service from menu overlay
         *
         * @param service
         */
        $scope.selectService = function (service) {
          $scope.close(service);
          if (service.type === 'product' || (service.info && service.info.type === 'product')) {
            switchView.selectService(service).catch((err) => {
              $log.error('[7Shop.Core] Change state from overlay error', {
                code: 'S_ERROR_OVERLAY_STATE_CHANGE',
                upstream_message: err.message,
                upstream_code: err.type,
                details: err.details
              });
            });
          } else {
            Modules.loadModule(service.id).then(function () {
              // if we are not on this module page already then navigate
              if (!$state.includes('app.home.' + service.id)) {
                $state.go('app.home.' + service.id).catch((err) => $log.error('[7Shop.Core] Failed to change route on module load', {
                  code: 'S_ERROR_OVERLAY_MODULE_STATE_CHANGE',
                  upstream_message: err.message,
                  upstream_code: err.type,
                  details: err.details
                }));
              }
            });
          }
        };

        $scope.close = function (selectedService) {
          // after login ticket for activated game maybe not active
          // because of race condition, lets just be sure that is activated on X too
          const gameId = selectedService?.id || SevenGamesSvc.activeGame?.id;

          if (firstLoad
            && gameId
            && switchView.isServiceActive(gameId)) {
            switchView.selectService(selectedService || SevenGamesSvc.activeGame).catch((err) => {
              $log.error('[7Shop.Core] Change state from overlay X key', {
                code: 'S_ERROR_OVERLAY_STATE_CHANGE_X_KEY',
                upstream_message: err.message,
                upstream_code: err.type,
                details: err.details
              });
            });
            firstLoad = false;
          }

          $scope.$evalAsync(function () {
            $scope.show = false;
          });
        };

        $scope.$watch('show', function (newValue) {
          arrangeOverlay();
          $rootScope.$emit('7S:Overlay.Show', newValue);
        });

        $scope.affectedProducts = SevenMaintenanceService.getAffectedProducts();
        $scope.disabledOverlayMenuProducts = {};

        $scope.$watchCollection('affectedProducts', function (newAffectedProducts) {
          if (!newAffectedProducts.length) {
            $scope.disabledOverlayMenuProducts = {};
            return;
          }

          newAffectedProducts.forEach((product) => {
            $scope.disabledOverlayMenuProducts[product] = true;
          });
        });

        /**
         * Get overlay root font size.
         * It is used to properly scale font inside overlay items
         * @param params
         * @returns {number}
         */
        this.getRootFontSize = function (params) {
          return params.width / 100;
        };

        /**
         * Get size of item inside menu overlay
         *
         * @param params {{width:Integer, height:Integer}}
         * @returns {{width: (string|*), height: (string|*)}}
         */
        this.getItemSize = function (params) {
          var columnNum = boxLength.column >= 3 ? boxLength.column : 3;
          var rowNum = boxLength.row >= 3 ? boxLength.row : 3;

          return {
            width: (params.width / columnNum) - 10,
            height: (params.height / rowNum) - 20
          };
        };
      }
    };
  }
})();
