import { has } from 'underscore';
import master from '@nsftx/seven-gravity-gateway/master';

/* @ngInject */
function rootScopeDecorator($delegate, $injector) {
  var Scope = $delegate.constructor;
  var origBroadcast = Scope.prototype.$broadcast;
  var origEmit = Scope.prototype.$emit;
  var eventWhiteList = [
    '7S:Betslip.BetAdded',
    '7S:Betslip.BetSelected',
    '7S:Betslip.TicketActivated',
    '7S:Betslip.Payin',
    '7S:Betslip.Reset',
    '7S:Betslip.SelectedBetUpdated',
    '7S:Betslip.BetRemoved',
    '7S:Betslip.Blocked',
    '7S:Betslip.Unblocked',
    '7S:Betslip.ReCreated',
    '7S:Betslip.Clear',
    '7S:Ticket.CheckWebCode',
    '7S:Tickets.PayingSentSuccess',
    '7S:Tickets.PayingFailed',
    '7S:Tickets.PayingSuccess',
    '7S:Tickets.PrePrint',
    '7S:Tickets.ReBet',
    '7S:Tickets.Checked',
    '7S:Betslip.BetsUpdated',
    '7S:Betslip.TicketConfigUpdated',
    '7S:Betslip.TicketStateChanged',
    '7S:Slave.Shown',
    '7S:UI.Show',
    '7S:User.AuthorizationChanged',
    '7S:Widget.Event',
    '7S:Slave.Unmute',
    '7S:Slave.Awake',
    '7S:Slave.Mute',
    '7S:Slave.Snooze',
    '7S:Tickets.PrintCopy',
    '7S:Tickets.GetActions',
    '7S:Tickets.Payout',
    '7S:Notifications.Clicked',
    '7S:Notifications.Received',
    '7S:Tickets.CashOut',
    '7S:Sportsbook.Cashout',
    '7S:Tickets.ReCheck',
    '7S:Tickets.Resolved'
  ];
  // force events althogut snooz is active
  var forceEvents = [
    'Tickets.PrePrint',
    'Betslip.Blocked',
    'Betslip.Unblocked',
    'Ticket.CheckWebCode',
    'Tickets.Payout',
    'Tickets.CashOut',
    'Sportsbook.Cashout',
    'Tickets.Resolved',
    'Tickets.GetActions',
    'User.AuthorizationChanged'
  ];
  var eventRegExWhiteList = {
    '7S:EventPublisher': {
      // allow everything from this namesapce exect for Subscribe and Publish
      regex: /7S:EventPublisher.(?!Subscribe|Publish).*/i
    }
  };

  function prepareData(data) {
    // this will also create Shallow copy of an object
    const dataToSend = { ...data };
    if (dataToSend !== null
      && typeof dataToSend === 'object') {
      // no need to send this, also we did Shallow copy so we can savelfy delete property
      delete dataToSend.productId;
    }

    return dataToSend;
  }

  function shouldSent(event) {
    var namespace = event.split('.');
    // test againest regex if there is any for this namespace (e.g 7S:EventPublisher)
    if (has(eventRegExWhiteList, namespace[0])) {
      return eventRegExWhiteList[namespace[0]].regex.test(event);
    }
    // otherwise just see is event whitelisted
    return eventWhiteList.indexOf(event) >= 0;
  }

  function stripProduct(currentEvent) {
    var stripProductFrom = ['7S:Tickets.PrePrint'];
    var index;

    // strip product fro event name e.g.
    // 7S:Tickets.PrePrint.LiveBetting to 7S:Tickets.PrePrint
    stripProductFrom.forEach(function (event) {
      if (currentEvent.indexOf(event) >= 0) {
        index = currentEvent.lastIndexOf('.');
        currentEvent = currentEvent.slice(0, index);
      }
    });

    return currentEvent;
  }

  function slaveFrameExist(productId) {
    let GravityGatewayIntegrator;
    let productModule;
    var instance = master();
    var slaves = [];

    // check is frame available
    if (instance && productId && productId !== '*') {
      GravityGatewayIntegrator = $injector.get('GravityGatewayIntegrator');
      productModule = GravityGatewayIntegrator.getModuleByProductId(productId);
      slaves = instance.getAll();
      return slaves[productModule?.config?.iframeConfig?.frameId || productId];
    }

    return instance;
  }

  function sendEvent(data) {
    var eventName = data[0];
    var eventData = data[1];
    const slave = slaveFrameExist(eventData?.productId);

    eventName = stripProduct(eventName);

    // if event is not whitelisted
    // or product is not in iframe just quit
    if (!shouldSent(eventName)
      || !slave) {
      return;
    }

    // strip 7S:
    if (eventName.slice(0, 3) === '7S:') {
      eventName = eventName.slice(3, eventName.length);
    }

    if (eventData.productId === '*') {
      master().sendToAll({
        action: eventName,
        data: prepareData(eventData),
        enforceEvent: forceEvents.indexOf(eventName) >= 0
      });
    } else if (eventData.async) {
      master().sendMessageAsync(slave.frameId, {
        action: eventName,
        productId: eventData.productId,
        data: prepareData(eventData),
        enforceEvent: forceEvents.indexOf(eventName) >= 0
      }).then(eventData.resolve)
        .catch(eventData.reject);
    } else {
      master()
        .sendMessage(slave.frameId, {
          action: eventName,
          productId: eventData.productId,
          data: prepareData(eventData),
          enforceEvent: forceEvents.indexOf(eventName) >= 0
        });
    }
  }

  Scope.prototype.$broadcast = function () {
    sendEvent(arguments);
    return origBroadcast.apply(this, arguments);
  };
  Scope.prototype.$emit = function () {
    sendEvent(arguments);
    return origEmit.apply(this, arguments);
  };
  return $delegate;
}

export default rootScopeDecorator;
