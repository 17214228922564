import PubSub from 'pubsub-js';

/* @ngInject */
function KeyEventsSvc(
  $rootScope,
  $log,
  KeyMapper,
  Shortcuts
) {
  return {
    processKeyEvent: function (e) {
      var code = String.fromCharCode(e.keyCode).toLowerCase();
      var specialKeys = '';
      var finalCode = '';
      var foundAction = false;
      var foundShortcut = false;

      specialKeys += e.ctrlKey ? 'ctrl+' : '';
      specialKeys += e.altKey ? 'alt+' : '';
      specialKeys += e.shiftKey ? 'shift+' : '';

      // If specialKeys is not ready
      // splice from end '+' and set code to empty. For example if we press
      // only shift we don't want to broadcast 'shit+shiftKeyCode' or 'ctrl+ctrlKeyCode'.
      // Result from this is just pure 'shift' or 'ctrl' string.
      if ([KeyMapper.shift_left.key, KeyMapper.alt_left.key, KeyMapper.ctrl_left.key].indexOf(e.keyCode) >= 0
        && specialKeys.charAt(specialKeys.length - 1) === '+') {
        // also erase shift/alt/ctrl code
        code = '';
        // strip +
        specialKeys = specialKeys.substring(0, specialKeys.length - 1);
      }

      finalCode = specialKeys + code;

      // alt + ctrl + something
      if ((e.altKey || e.ctrlKey)
        && e.keyCode !== KeyMapper.shift_left.key) {
        foundAction = Shortcuts.getByShortcut(finalCode);
        // don't broadcast if shortcut does not exist
        if (foundAction.length) {
          $rootScope.$evalAsync(function () {
            $log.debug('[7Shop.Core] KeyboardEvent', {
              action: foundAction[0].action
            });
            PubSub.publishSync(`7S:KeyboardEvent.${foundAction[0].action}`, {
              event: e,
              shortcut: foundAction,
              code: finalCode
            });
          });
        }
        return;
      }

      foundShortcut = Shortcuts.findByKeyCode(e.keyCode);
      $rootScope.$evalAsync(() => {
        const eventName = foundShortcut ? `7S:KeyboardEvent.${foundShortcut.action}` : '7S:KeyboardEvent';
        PubSub.publishSync(eventName, {
          event: e,
          shortcut: foundShortcut,
          code: finalCode
        });
      });
    }
  };
}

export default KeyEventsSvc;
