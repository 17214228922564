import PubSub from 'pubsub-js';
import templateUrl from './galeryTemplate.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sImgGallery', function sImgGallery() {
      return {
        templateUrl: templateUrl,
        scope: {
          images: '='
        },
        link: function (scope) {
          scope.lightbox = {
            visible: false,
            imgSrc: null
          };

          scope.showImage = function (img) {
            scope.lightbox = {
              visible: true,
              imgSrc: img
            };
          };

          scope.closeLightbox = function () {
            scope.lightbox.visible = false;
            scope.lightbox.imgSrc = null;
          };

          const escListener = PubSub.subscribe('7S:KeyboardEvent', (e, event) => {
            if (event.event.key === 'Escape') {
              scope.closeLightbox();
            }
          });

          scope.$on('$destroy', () => {
            PubSub.unsubscribe(escListener);
          });
        }
      };
    });
})();
