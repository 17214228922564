(function () {
  /* eslint-disable no-multi-assign */
  var ShopDeviceDataLoaders = window.ShopDeviceDataLoaders = window.ShopDeviceDataLoaders || {};
  var injector = angular.injector(['ng']);
  var $q = injector.get('$q');

  ShopDeviceDataLoaders.localStorageLoader = {

    isInstalled: function () {
      return localStorage.getItem('device.appConfig') ? $q.resolve() : $q.reject();
    },

    /**
     *
     */
    getData: function () {
      return $q.resolve({
        appConfig: {
          data: JSON.parse(localStorage.getItem('device.appConfig'))
        },
        device: {
          data: JSON.parse(localStorage.getItem('device.data'))
        }
      });
    }
  };
})();
