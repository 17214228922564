/**
* @class GravityFormsProvider
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function GravityFormsProvider() {
  var self = this;

  /**
   * @memberOf module:"7Shop.Core".GravityForms
   */
  this._data = {};

  /**
   * @memberOf module:"7Shop.Core".GravityForms
   */
  this.setData = function (formsData) {
    this._data = formsData;
  };

  /**
   * @memberOf module:"7Shop.Core".GravityForms
   * @returns {*}
   */
  this.getData = function () {
    return this._data;
  };

  /**
   * Returns form array
   *
   * @memberOf module:"7Shop.Core".GravityForms
   * @param {String} formName
   * @return {Array}
   */
  this.getForm = function (formName) {
    return this._data[formName];
  };

  /**
   * @memberOf module:"7Shop.Core".GravityForms
   */
  this.$get = function () {
    return self;
  };
}

export default GravityFormsProvider;
