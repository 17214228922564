// Module
var code = `<div class="s-calculator"> <div class="s-calculator__content" stop-event="click"> <div class="row"> <div class="col-13" ng-if="!\$ctrl.calculator.pinned"> <h5 class="mb-1 px-0-5 pb-0-5"> {{'main.session_tickets' | translate}} </h5> <div class="p-0-5"> <table class="ticket_help-table"> <tr ng-repeat="ticket in \$ctrl.session.tickets | orderBy: '-pendingStartedAt' track by (ticket.requestUuid + ticket.action)" class="last_tickets_row text-center"> <td ng-attr-title="{{ticket.product}}" ng-style="{'border-left-color': ticket.config.color}" width="130"> <div ng-switch="ticket.product"> <div ng-switch-when="Voucher">{{ 'main.voucher' | translate }}</div> <div ng-switch-when="Withdrawal">{{ 'main.withdrawal' | translate }}</div> <div ng-switch-when="DirectDeposit">{{ 'main.direct_transfers' | translate }}</div> <div ng-switch-default>{{ ticket.id }}</div> </div> </td> <td ng-attr-title="{{ticket.requestUuid}}"> {{ticket.payin || ticket.totalAmount | number:2}} </td> <td ng-attr-title="{{ticket.pendingStartedAt | date:'dd.MM.yyyy HH:mm:ss'}}"> {{ticket.pendingStartedAt | date:'HH:mm:ss'}} </td> <td ng-attr-title="{{'ticket.status_' + (ticket.status.value || ticket.status) | lowercase | translate | capitalize}}"> <span class="status {{ticket.localStatus | lowercase}}" ng-class="{'status-alternative': ticket.hasOwnProperty('alternativeStake')}"> </span> </td> </tr> </table> </div> </div> <div class="col-11" ng-class="{'col-24': \$ctrl.calculator.pinned}"> <div ng-if="\$ctrl.calculator.overlayTemplateVisible" ng-class="{'inline': \$ctrl.calculator.pinned}" ng-include="\$ctrl.calculator.overlayTemplate"> </div> <div ng-if="!\$ctrl.calculator.overlayTemplateVisible"> <div class="s-calculator__header row m-0 p-0-5"> <h5 class="col p-0"> {{'main.calculator' | translate | capitalize}} </h5> <div class="s-calculator__pin col text-right p-0" ng-click="\$ctrl.toggleCalculatorPinned()"> <i class="n-i mt-0-5" ng-class="{'n-i-popout': \$ctrl.calculator.pinned,
                                          'n-i-minimize-b': !\$ctrl.calculator.pinned}"> </i> </div> </div> <div class="s-calculator__inputs" ng-class="{'inline': \$ctrl.calculator.pinned}"> <div class="form-group"> <div class="input-group px-0-5"> <div class="input-group-prepend"> <span translate="calculator.money_in" class="input-group-text"></span> </div> <input type="text" class="form-control font-weight-bold text-right" min="0" tabindex="98" ng-model="\$ctrl.moneyIn" number-delimiter s-auto-select-text focus-next="18" focus-next-event="keydown" focus-next-on-key="13"> </div> </div> <div class="form-group"> <div class="input-group px-0-5"> <div class="input-group-prepend"> <span translate="main.payin" class="input-group-text"></span> </div> <input type="text" class="form-control font-weight-bold text-right" value="{{\$ctrl.session.payment | number: 2}}" disabled="disabled"> </div> </div> <div class="form-group"> <div class="input-group px-0-5"> <div class="input-group-prepend"> <span translate="main.payout" class="input-group-text"></span> </div> <input type="text" class="form-control font-weight-bold text-right" value="{{\$ctrl.session.payout | number: 2}}" disabled="disabled"> </div> </div> <div class="form-group"> <div class="input-group px-0-5"> <div class="input-group-prepend"> <span translate="{{ \$ctrl.restMoneyTranslation }}" class="input-group-text"></span> </div> <input name="restMoneyAmount" type="text" class="form-control font-weight-bold text-right color-{{ \$ctrl.restMoneyColor }}" ng-style="{
                                        backgroundColor: \$ctrl.restMoneyColor,
                                        borderColor: \$ctrl.restMoneyColor
                                       }" value="{{ \$ctrl.restMoneyAmount < 0
                                        ? \$ctrl.restMoneyAmount * (-1)
                                        : \$ctrl.restMoneyAmount
                                        | number: 2 }}" disabled="disabled"> </div> </div> <div class="form-group px-0-5"> <button class="btn btn-success" tabindex="18" ng-class="{'btn-block': !\$ctrl.calculator.pinned}" ng-click="\$ctrl.resetCalculator()" ng-keydown="\$event.keyCode == 13
                                                && \$ctrl.resetCalculator()" translate="main.reset_calculator"> </button> </div> </div> </div> </div> </div> </div> </div> `;
// Exports
var _module_exports =code;;
var path = '/core/calculator/calculator.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;