/* @ngInject */
export default function nabTrans($q, $translate) {
  /**
   * @constructor nabTrans
   */
  return {
    /**
       * Gets translation string through $translate service
       * and handles dynamic parameters replacement
       *
       * @memberOf nabTrans
       * @param {string} transKey - the string to be translated
       * @param {Object} [dynamicParams] (optional) - object containing data that needs to be inserted in translation
       * @param {boolean} [instant=false] -tells method to perform synchronous operation
       * @returns Promise{*} (async) or String (sync)
       */
    translate: function (transKey, dynamicParams, instant) {
      var def;
      if (instant) {
        return $translate.instant(transKey, dynamicParams);
      }

      def = $q.defer();

      $translate(transKey, dynamicParams).then(function (trans) {
        def.resolve(trans);
      }).catch(function () {
        def.reject('Translate failed for: ', transKey);
      });

      return def.promise;
    }
  };
}
