/**
 *
 * @class PrintListeners
 * @memberOf module:"7Shop.Print"
 */
/* @ngInject */
function PrintListeners(
  $rootScope,
  $injector,
  $log,
  printServiceApi,
  PeripheralsSvc,
  NabNotifications,
  ShopPrintService
) {
  return {
    init: function () {
      if ($injector.has('localPubSubApi')) {
        this.initNpsListeners();
      }
    },

    initNpsListeners: function () {
      var localPubSubApi = $injector.get('localPubSubApi');
      // after SPS is fully iplemented we can move this to nps module
      function setListeners() {
        localPubSubApi.subscribe('printerState');
        localPubSubApi.on('printerState', function (message) {
          if (message.type === 'statusChange') {
            printServiceApi.setPrinterStatus(message).then(
              function (response) {
                PeripheralsSvc.updatePeripheralStatus('PRINTER', {
                  valid: true,
                  statusLevel: response.code,
                  errorMessage: response.message
                });
                // printer status ok
                NabNotifications.closeNotificationWithId('printer_error');
              },
              function (response) {
                PeripheralsSvc.updatePeripheralStatus('PRINTER', {
                  valid: false,
                  statusLevel: response.code,
                  errorMessage: response.message
                });
                // printer error
                NabNotifications.show({
                  message: response.message,
                  type: 'warning',
                  id: 'printer_error'
                });
              }
            );
          }
        }, false);
      }

      setListeners();
      $rootScope.$on('LocalScm.ReConnect', setListeners);
      $rootScope.$on('LocalScm.Disconnect', function () {
        localPubSubApi.connection.removeListener('printerState');
      });
    },
    /**
     * @memberOf PrintListeners
     */
    setPrinterListeners: function () {
      /**
       * @event "7S:Peripherals.Print"
       * @memberOf module:"7Shop.Print".PrintListeners
       * @type {Object}
       *
       * @property {Object} data
       * @property {String} data.type - e.g. ticket
       * @property {String} data.action - e.g. Cancel
       * @property {String} data.productId
       * @property {Object} data.data - e.g. ticket object
       * @property {Object} data.additionalData
       */
      $rootScope.$on(
        '7S:Peripherals.Print',
        /**
         *
         * @param event
         * @param {Object} eventData
         * @param {Object} eventData.data
         * @param {String} eventData.data.type
         * @param {String} eventData.data.action
         * @param {String} eventData.data.productId
         * @param {Object} eventData.data.data
         * @param {Object} eventData.data.additionalData
         * @param {String} eventData.data.npsJobName
         * @param {String} eventData.data.layoutName
         * @param {String} eventData.data.layout
         */
        function (event, eventData) {
          ShopPrintService.printJob(
            {
              type: eventData.data.type,
              action: eventData.data.action,
              productId: eventData.data.productId,
              npsJobName: eventData.data.npsJobName,
              layoutName: eventData.data.layoutName,
              layout: eventData.data.layout
            },
            eventData.data.data,
            eventData.data.additionalData
          ).catch((err) => {
            $log.error(`[7Shop.Print] Failed to print on Peripherals.Print event. ${err.message}`, {
              action: eventData.data.action,
              product_displayid: eventData.data.productId,
              upstream_message: err.upstream_message,
              upstream_code: err.upstream_code,
              code: 'S_PERIPHERALS_PRINT_ERROR'
            });
            NabNotifications.show({
              message: err.message,
              type: 'warning',
              delay: 3000
            });
          });
        }
      );

      /**
       * @event "7S:Peripherals.UpdateConfig"
       * @memberOf module:"7Shop.Print".PrintListeners
       * @type {Object}
       *
       * @property {Object} data
       * @property {Object} data.printService
       * @property {String} data.printService.oddsType - calls printServiceApi.updateOddsConfig()
       * @property {Object} data.printService.taxes
       * @property {Object} data.printService.taxes.payin
       * @property {Object} data.printService.taxes.payout
       * @property {Object} data.printService.ticketConfig - calls printServiceApi.updateTicketConfig()
       * @property {Object} data.printService.ticketDetailsConfig - calls printServiceApi.updateTicketDetailsConfig()
       * @property {Object} data.printService.printItemTemplate - calls printServiceApi.extendPrintItemTemplate()
       * @property {String} data.printService.printItemTemplate.section
       * @property {String} data.printService.printItemTemplate.type
       * @property {Object} data.printService.printItemTemplate.data
       * @property {Object} data.printService.commonData - calls printServiceApi.updateCommonData()
       * @property {String} productId
       */
      $rootScope.$on(
        '7S:Peripherals.UpdateConfig',
        /**
         *
         * @param event
         * @param {Object} eventData
         * @param {String} eventData.productId
         * @param {String} eventData.data.printService.oddsType
         * @param {Object} eventData.data.printService.taxes
         * @param {Object} eventData.data.printService.taxes.payin
         * @param {Object} eventData.data.printService.taxes.payout
         * @param {Object} eventData.data.printService.ticketConfig
         * @param {Object} eventData.data.printService.ticketDetailsConfig
         * @param {Object} eventData.data.printService.printItemTemplate
         * @param {String} eventData.data.printService.printItemTemplate.section
         * @param {String} eventData.data.printService.printItemTemplate.type
         * @param {Object} eventData.data.printService.printItemTemplate.data
         * @param {Object} eventData.data.printService.commonData
         */
        function (event, eventData) {
          var taxConfig;

          if (eventData.data.printService) {
            if (eventData.data.printService.taxes) {
              taxConfig = {
                tax: {}
              };

              taxConfig.tax[eventData.productId] = eventData.data.printService.taxes
                || { payin: { value: false }, payout: { value: false } };

              printServiceApi.updateTicketConfig(taxConfig);
            }

            if (eventData.data.printService.oddsType) {
              printServiceApi.updateOddsConfig(
                eventData.productId,
                eventData.data.printService.oddsType
              );
            }

            if (eventData.data.printService.ticketConfig) {
              printServiceApi.updateTicketConfig(eventData.data.printService.ticketConfig);
            }

            if (eventData.data.printService.ticketDetailsConfig) {
              printServiceApi.updateTicketDetailsConfig(
                eventData.productId,
                eventData.data.printService.ticketDetailsConfig
              );
            }

            if (eventData.data.printService.printWinningDetails) {
              printServiceApi.updateTicketDetailsConfig(
                eventData.productId,
                {
                  printWinningDetails: eventData.data.printService.printWinningDetails,
                  printPayoutDetails: eventData.data.printService.printWiprintPayoutDetailsnningDetails
                }
              );
            }

            if (eventData.data.printService.printItemTemplate) {
              printServiceApi.extendPrintItemTemplate(
                eventData.data.printService.printItemTemplate.section,
                eventData.data.printService.printItemTemplate.type,
                eventData.data.printService.printItemTemplate.data
              );
            }

            if (eventData.data.printService.commonData) {
              printServiceApi.updateCommonData(eventData.data.printService.commonData);
            }
          }
        }
      );

      /**
       * @event "7S:Peripherals.PrintA4"
       * @memberOf module:"7Shop.Print".PrintListeners
       * @property {Object} data
       * @property {String} data.documentUrl
       */
      $rootScope.$on(
        '7S:Peripherals.PrintA4',
        /**
         *
         * @param event
         * @param {Object} eventData
         * @param {String} eventData.data.documentUrl.
         */
        function (event, eventData) {
          ShopPrintService.printA4(eventData.data.documentUrl);
        }
      );

      /**
       *
       * Listen for message to update printer state.
       * Depending on passed state:
       *  - it will change printer icon color
       *  - it will prevent money actions (payin, payout, cancel) if valid is set to false
       *
       * @event module:"7Shop.Print"."7S:Peripherals.UpdatePrinterState"
       * @type {Object}
       * @property {Object} data
       * @property {Boolean} data.valid - Either true or false.
       * @property {String} data.statusLevel - It will affect color of printer icon in bottom right corner.
       * Possible values: ok (green), error (red), warning (yellow), unknown (gray).
       * @property {String} data.errorMessage - If status is not valid ti will show this message to user.
       */
      $rootScope.$on(
        '7S:Peripherals.UpdatePrinterState',
        function (e, eventData) {
          PeripheralsSvc.updatePeripheralStatus('PRINTER', eventData.data);
        }
      );
    }
  };
}

export default PrintListeners;
