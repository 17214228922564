(function () {
  angular.module('7Shop.Core')
    .provider('LoginData', LoginDataProvider);

  /**
   * @typedef {Object} LoginData
   * @memberof module:"7Shop.Core".LoginDataProvider
   * @property {Object} appConfig
   * @property {Object} appRoutes
   * @property {SevenBetshop} betshop
   * @property {Object} betshopGroup
   * @property {Array} clientAppModules
   * @property {Object} cloudMessaging
   * @property {SevenCompany} company
   * @property {Object} configRoutes
   * @property {NarDevice} device
   * @property {Object} ticketActions
   * @property {Object} user
   * @property {String} userType
   * @property {Array.<String>} roles
   */

  /**
   *
   * @constructor LoginDataProvider
   * @memberof module:"7Shop.Core"
   * @augments LoginData
   */
  function LoginDataProvider() {
    var self = this;

    this.setData = function (data) {
      angular.extend(self, data);
    };

    /**
     *
     * @returns {LoginData}
     */
    this.$get = function () {
      return self;
    };
  }
})();
