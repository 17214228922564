(function () {
  angular.module('7Shop.Core')
    .service('OverlaySvc', OverlaySvc);

  function OverlaySvc() {
    var Overlay = null;

    this.registerOverlay = function (overlayApi) {
      Overlay = overlayApi;
    };

    this.open = function (view) {
      if (Overlay) {
        Overlay.open(view);
      }
    };

    this.close = function (view) {
      if (Overlay) {
        Overlay.close(view);
      }
    };

    this.isOpen = function () {
      return Overlay && Overlay.show;
    };
  }
})();
