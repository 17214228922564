/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
function modalSvcConfig() {
  return {
    options: {
      rootEl: ''
    },
    $get: function () {
      return this.options;
    },
    set: function (keyOrHash, value) {
      let k;
      let v;
      let _results;
      if (typeof keyOrHash === 'object') {
        _results = [];
        for (k in keyOrHash) {
          v = keyOrHash[k];
          _results.push(this.options[k] = v);
        }
        return _results;
      }
      this.options[keyOrHash] = value;
      return this.options[keyOrHash];
    }
  };
}

export default modalSvcConfig;
