(function () {
  angular.module('7Shop.Core')
    .provider('GravityMenus', GravityMenusProvider);

  /**
   * @memberOf module:"7Shop.Core"
   * @class GravityMenusProvider
   */
  function GravityMenusProvider() {
    var self = this;

    /**
     * @memberOf module:"7Shop.Core".GravityMenus
     */
    this._data = {};

    /**
     * @memberOf module:"7Shop.Core".GravityMenus
     */
    this.setData = function (data) {
      this._data = data;
    };

    /**
     * @memberOf module:"7Shop.Core".GravityMenus
     * @returns {*}
     */
    this.getData = function () {
      return this._data;
    };

    /**
     * Returns menu array
     *
     * @memberOf module:"7Shop.Core".GravityMenus
     * @param {String} menuName
     * @return {Array}
     */
    this.getMenu = function (menuName) {
      return this._data[menuName] || [];
    };

    /**
     * Reduce menu Array into Object
     *
     * @param {String} menuName
     * @return {Object}
     */
    this.getMenuAsObjectOfPriorities = function (menuName) {
      var menu = this.getMenu(menuName);

      // returns Object: { url: priority }
      var menuReduced = menu.reduce(function (obj, menuItem) {
        obj[menuItem.url] = menuItem.priority;
        return obj;
      }, {});

      return menuReduced;
    };

    /**
     *
     */
    this.$get = function () {
      return self;
    };
  }
})();
