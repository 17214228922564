import PeripheralsSvc from './peripheralsService';

/**
 * @module "7Shop.Peripherals"
 */
const moduleName = angular.module('7Shop.Peripherals', [])
  .service('PeripheralsSvc', PeripheralsSvc)
  .name;

export default moduleName;
