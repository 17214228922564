import overlayMenuLabelTemplate from './overlayMenuLabel.html';
import './_label.scss';

/**
 * @ngInject
 */

function OverlayMenuLabelController() {
}

export default {
  templateUrl: overlayMenuLabelTemplate,
  controller: OverlayMenuLabelController,
  controllerAs: 'ctrl',
  bindings: {
    label: '@'
  }
};
