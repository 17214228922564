/**
* @class GravityLayoutsProvider
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function GravityLayoutsProvider() {
  var self = this;

  /**
   * @memberOf module:"7Shop.Core".GravityLayouts
   */
  this._data = [];

  /**
   * @memberOf module:"7Shop.Core".GravityLayouts
   * @param {Array.<Object>} layouts
   * @param {number} layouts[].id
   * @param {string} layouts[].name
   * @param {string} layouts[].title
   * @param {string} layouts[].template
   */
  this.setData = function (layouts) {
    this._data = layouts;
  };

  /**
   * @memberOf module:"7Shop.Core".GravityLayouts
   * @returns {Array}
   */
  this.getData = function () {
    return this._data;
  };

  /**
   * Returns layout from layouts array filtered by layout name
   *
   * @memberOf module:"7Shop.Core".GravityLayouts
   * @param {String} layoutName
   * @return {Object}
   */
  this.getLayout = function (layoutName) {
    return this._data.find((layout) => layout.name === layoutName);
  };

  /**
   * @memberOf module:"7Shop.Core".GravityLayouts
   */
  this.$get = function () {
    return self;
  };
}

export default GravityLayoutsProvider;
