/* @ngInject */
export default function sClock(
  $interval,
  $filter
) {
  return {
    restrict: 'A',
    scope: {
      format: '='
    },

    link: function (scope, elem) {
      var updateClock = function () {
        elem[0].textContent = $filter('date')(
          new Date(),
          scope.format
        );
      };
      var intervalHolder = $interval(function () {
        updateClock();
      }, 5000, false, false);

      updateClock();

      // clear interval on destroy
      scope.$on('$destroy', function () {
        $interval.cancel(intervalHolder);
      });
    }
  };
}
