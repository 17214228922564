/* eslint-disable angular/document-service */
(function () {
  angular.module('7Shop.GoogleAnalytics')
    .factory('googleAnalyticsService', googleAnalyticsService);

  function googleAnalyticsService(
    $rootScope,
    NabENV,
    LocalData,
    GravitySettings
  ) {
    // Constants
    /**
     * 'Ticket print time (ms)' event/hit
     * Object used to store timestamps
     */
    const ticketPrintTimestamps = {};

    function init() {
      initEvents();
    }

    function initEvents() {
      $rootScope.$on('7S:Bootstrap.Ready', () => {
        loadTrackingScript();
        initTrackEventListeners();
      });
    }

    function loadTrackingScript() {
      const analyticsTrackingId = GravitySettings.getModuleDataKeyValue(
        'analytics',
        'googleAnalyticsTrackingId'
      );
      const deviceUuid = LocalData.device.uuid;
      const trackingId = analyticsTrackingId || NabENV.analytics.trackingId;
      const gtagTrackingScript = document.createElement('script');
      gtagTrackingScript.setAttribute('async', 'true');
      gtagTrackingScript.setAttribute(
        'src',
        `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
      );

      const gtagConfigScript = document.createElement('script');
      gtagConfigScript.innerText = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}');
      gtag('set', {client_id: '${deviceUuid}'})`;

      document.head.appendChild(gtagTrackingScript);
      document.head.appendChild(gtagConfigScript);
    }

    function initTrackEventListeners() {
      const analyticsList = GravitySettings.getModuleDataKeyValue('analytics', 'googleAnalyticsList') || [];

      /**
       * 'Ticket print time (ms)' event/hit
       * After ticket requestUuid has beed generated save timestamp
       *
       * @param {Object} data
       * @param {String} data.requestUuid
       */
      if (analyticsList.indexOf('Ticket print time (ms)') !== -1) {
        $rootScope.$on('7S:TicketManager.BeforeTicketPayin', function (event, data) {
          if (data.requestUuid) {
            ticketPrintTimestamps[data.requestUuid] = moment().valueOf();
          }
        });
      }

      /**
       * 'Ticket print time (ms)' event/hit
       * On ticket resolve calculate ticket print time
       *
       * @param {Object} data
       * @param {Object} data.ticketData
       * @param {Object} data.productId
       */
      if (analyticsList.indexOf('Ticket print time (ms)') !== -1) {
        $rootScope.$on('7S:Tickets.AddSuccess', function (event, data) {
          var requestUuid = data.ticketData.requestUuid;
          var product = data.productId || data.ticketData.product;
          var timestamp;

          if (ticketPrintTimestamps[requestUuid]) {
            timestamp = moment().valueOf() - ticketPrintTimestamps[requestUuid];
            trackEvent('Ticket_Print_Time', {
              product,
              value: timestamp
            });
            delete ticketPrintTimestamps[requestUuid];
          }
        });
      }

      /**
       * 'Rebet' event/hit
       *
       * @param {Object} data
       * @param {Object} data.ticket
       * @param {String} data.type
       */
      if (analyticsList.indexOf('Rebet') !== -1) {
        $rootScope.$on('Ticket.ReBet', function (event, data) {
          const ticketDate = moment(data.ticket.ticketDateTimeUTC).format('DD MM YYYY');
          const currentDate = moment().format('DD MM YYYY');
          const dateMatch = ticketDate === currentDate
            ? 'Same day'
            : 'Different day';

          trackEvent('Rebet', {
            source: data.type,
            rebetDate: dateMatch
          });
        });
      }
    }

    /**
     * Sends a custom event to GA4.
     *
     * @param {string} eventName
     * @param {Object} eventParams
     */
    function trackEvent(eventName, eventParams) {
      if (typeof gtag === 'function') {
      /* eslint-disable no-undef */
        gtag('event', eventName, eventParams);
      }
    }

    return {
      init,
      trackEvent
    };
  }
})();
